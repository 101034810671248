import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import Router from "@/router/index.js";
import tools from "@/utils/tools.js";
import globalConfig, { chinaAppName } from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import { releaseSong } from "@/api/api/apiStore.js";
import sseGenerateApi from "@/api/api/apiSse.js";
import {
  getProducts,
  checkFirstPurchase,
  manageSubscription,
  getInvitePop,
} from "@/api/api/apiCredit.js";
import ShowInviteDialog from "@/components/inviteDialog/inviteDialog.js";

import message from "@/components/functionCallComponent/message.jsx";
import { showActionDialog } from "@/components/functionCallComponent/action/action.js";
import { $$language, $$t } from "@/i18n/i18n.js";
import objectStorage, {
  getObjectStorageFullUrl,
} from "@/lib/objectStorage/objectStorage.js";

import useUserDataStore from "@/store/userDataStore.js";

import useDraftDataStore from "./draftDataStore.js";
import useLyricDataStore from "./lyricDataStore.js";
import useMuseDataStore from "./museDataStore.js";
import userReferMusicStore from "./referMusicStore.js";
import useGlobalPlayDataStore from "@/store/globalPlayDataStore.js";
import useCreateLayoutStore from "@/store/create/createLayoutStore.js";
import useStoreDetailData from "@/store/storeDetail/storeDetailDataStore.js";
import usePromptDataStore from "@/store/create/promptDataStore.js";
import useCreateEasyModeStore from "@/store/create/createEasyModeStore.js";

import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js";
// const draftDataStore = useDraftDataStore();
// const lyricDataStore = useLyricDataStore();
// const museDataStore = useMuseDataStore();
// const referMusicStore = userReferMusicStore();
// const promptDataStore = usePromptDataStore();
// const createEasyModeStore = useCreateEasyModeStore();


const TabDataSuffixLists = [
  "createdresult",
  "likemysong",
  "mysong",
  "publishedmysong",
];
export const TabDataSuffixMap = {
  mysong: "mysong",
  likemysong: "likemysong",
  createdresult: "createdresult",
  publishedmysong: "publishedmysong",
};

const FeedListApiMap = {
  mysong_true: feedListApi.getFilterFeedList,
  mysong_false: feedListApi.getFeedList,

  likemysong_true: feedListApi.getFilterFeedList,
  likemysong_false: feedListApi.getFilterFeedList,

  createdresult_true: feedListApi.getFilterFeedList,
  createdresult_false: feedListApi.getFeedList,

  publishedmysong_true: feedListApi.getFilterFeedList,
  publishedmysong_false: feedListApi.getFilterFeedList,
};

const useFeedListStore = defineStore({
  id: "FeedListStore",
  state: () => {
    return {
      feedlist_createdresult: [],
      total_createdresult: 0,
      loadMoreId_createdresult: "",
      isloading_createdresult: true,
      hasInit_createdresult: false,
      hasMore_createdresult: false,

      feedlist_likemysong: [],
      total_likemysong: 0,
      loadMoreId_likemysong: "",
      isloading_likemysong: true,
      hasInit_likemysong: false,
      hasMore_likemysong: false,

      feedlist_mysong: [],
      total_mysong: 0,
      loadMoreId_mysong: "",
      isloading_mysong: true,
      hasInit_mysong: false,
      hasMore_mysong: false,

      feedlist_publishedmysong: [],
      total_publishedmysong: 0,
      loadMoreId_publishedmysong: "",
      isloading_publishedmysong: true,
      hasInit_publishedmysong: false,
      hasMore_publishedmysong: false,

      refFeedList: null,

      curSseConnectIdMap: {},
      curGenerateFeedIdList: [],
      sseGeneratingMap: {},
      curGenerateFeedCount: 0,
      curGeneratingFeedIdList: [],

      generating: false,
      initGenerateFeedData: null,

      vipTipVisible: true,
      mysongLastTab: "mysong",
      release: {
        showReleaseModal: false,
        showReleaseFormModal: false,
        showReleaseResultModal: false,
        showReleaseFailModal: false,
        failReason:
          "Reason：Here is the content，here is the content, here is the content",
        reReleaseCallback: () => { },
        release_info: null,
        item: null,
        pitem: null,
      },
    };
  },
  actions: {

    setMysongTab(tab) {
      this.mysongLastTab = tab
    },
    setVipTipVisible(visible) {
      this.vipTipVisible = visible;
    },
    clearData() {
      this.feedlist_createdresult = [];
      this.loadMoreId_createdresult = "";
      this.isloading_createdresult = true;
      this.hasInit_createdresult = false;
      this.hasMore_createdresult = false;

      this.feedlist_likemysong = [];
      this.loadMoreId_likemysong = "";
      this.isloading_likemysong = true;
      this.hasInit_likemysong = false;
      this.hasMore_likemysong = false;

      this.feedlist_mysong = [];
      this.loadMoreId_mysong = "";
      this.isloading_mysong = true;
      this.hasInit_mysong = false;
      this.hasMore_mysong = false;

      this.curSseConnectIdMap = {};
      this.curGenerateFeedIdList = [];
      this.curGenerateFeedCount = 0;
      Object.keys(this.sseGeneratingMap).forEach((key) => {
        this.sseGeneratingMap[key]?.abort();
      });
      this.sseGeneratingMap = {};
      this.curGeneratingFeedIdList = [];
      this.generating = this.getIsGenerating();


    },
    initFeedData(data, params) {
      const { listRenderType } = params;

      this[`feedlist_${TabDataSuffixMap[listRenderType]}`] = data?.list ?? [];
      this[`total_${TabDataSuffixMap[listRenderType]}`] = data?.total ?? 0;
      if (this.initGenerateFeedData && listRenderType == "createdresult") {
        if (
          !this[`feedlist_${TabDataSuffixMap[listRenderType]}`].some(
            (item) => item.feed_id === this.initGenerateFeedData?.feed_id
          )
        ) {
          this[`feedlist_${TabDataSuffixMap[listRenderType]}`].unshift(
            this.initGenerateFeedData
          );
          this[`feedlist_${TabDataSuffixMap[listRenderType]}`] = [].concat(
            this[`feedlist_${TabDataSuffixMap[listRenderType]}`]
          );
        }
        this.initGenerateFeedData = null;
      }
      this[`loadMoreId_${TabDataSuffixMap[listRenderType]}`] =
        data?.last_id ?? "";
      if (listRenderType == "createdresult") {
        this.updateGenerateFeedDataSse(this.feedlist_createdresult);
      }
      return this[`feedlist_${TabDataSuffixMap[listRenderType]}`];
    },

    updateGenerateFeedDataSse(datas) {
      for (let i = datas.length - 1; i >= 0; i--) {
        if ([1, 2].indexOf(datas[i].state) >= 0) {
          this.doGenerateConnectedSSE.call(this, datas[i]?.conn_id, datas[i]?.feed_id);
        } else {
          if (this.curGenerateFeedIdList.includes(datas[i]?.feed_id)) {
            this.curGenerateFeedCount--;
            this.curGenerateFeedIdList.splice(this.curGenerateFeedIdList.indexOf(datas[i]?.feed_id), 1);
            this.curGeneratingFeedIdList.splice(this.curGeneratingFeedIdList.indexOf(datas[i]?.feed_id), 1);
            delete this.curSseConnectIdMap[datas[i]?.feed_id];
            this.sseGeneratingMap[datas[i]?.feed_id]?.abort();
            delete this.sseGeneratingMap[datas[i]?.feed_id];
          }
        }
      }
    },

    getFeedList(params, done, errordone) {
      const { listRenderType } = params;
      this[`isloading_${TabDataSuffixMap[listRenderType]}`] = true;
      this[`params_${listRenderType}`] = params;
      if (!params.title) {
        delete params.title;
      }
      return new Promise(async (resolve, reject) => {
        FeedListApiMap[
          `${listRenderType}_${!!this[`params_${listRenderType}`].title}`
        ](params)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              this[`hasMore_${TabDataSuffixMap[listRenderType]}`] = !!data.more;
              let resData = this.initFeedData(data, params);
              resolve(resData);
              done(resData, !data.more);
            } else {
              errordone();
            }
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
          })
          .catch((e) => {
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
            reject(false);
          });
      });
    },

    initialize() {
      this.init(this[`params_${listRenderType}`] ?? {});
    },

    init(params = {}, done, errordone, isSearch, showLoading) {
      const { listRenderType } = params;
      let closeMsg = null;
      return new Promise((resolve, reject) => {
        if (showLoading) {
          closeMsg = message.loading({
            position: "center",
            content: $$t("create.loading"),
          });
        }

        this.getFeedList(params, done, errordone, isSearch ?? true)
          .then((datas) => {
            this[`hasInit_${TabDataSuffixMap[listRenderType]}`] = true;
            resolve(datas);
            closeMsg && closeMsg.clear();
          })
          .catch((err) => {
            closeMsg && closeMsg.clear();
            reject(err);
          });
      });
    },

    async refresh(params, done, errordone) {
      const { listRenderType } = params;
      return new Promise((resolve, reject) => {
        if (this[`isloading_${TabDataSuffixMap[listRenderType]}`]) {
          done && done();
          return;
        }
        this.getFeedList(params, done, errordone)
          .then((datas) => {
            resolve(datas);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loadMore(done, errordone, listRenderType) {
      return new Promise((resolve, reject) => {
        this[`params_${listRenderType}`].last_id =
          this[`loadMoreId_${TabDataSuffixMap[listRenderType]}`];
        if (this[`isloading_${TabDataSuffixMap[listRenderType]}`]) {
          done && done();
          reject("loading");
          return;
        }
        if (
          !this[`params_${listRenderType}`].last_id ||
          !this[`hasMore_${TabDataSuffixMap[listRenderType]}`]
        ) {
          done && done([], true);
          reject("no more");
          return;
        }
        this[`isloading_${TabDataSuffixMap[listRenderType]}`] = true;
        FeedListApiMap[
          `${listRenderType}_${!!this[`params_${listRenderType}`].title}`
        ](this[`params_${listRenderType}`])
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              this[`loadMoreId_${TabDataSuffixMap[listRenderType]}`] =
                data.last_id;
              this[`feedlist_${TabDataSuffixMap[listRenderType]}`] = []
                .concat(this[`feedlist_${TabDataSuffixMap[listRenderType]}`])
                .concat(data.list ?? []);
              this.addFeedDataToPlayList(data.list ?? []);
              done(data.list ?? [], !data.more);
              this[`hasMore_${TabDataSuffixMap[listRenderType]}`] = !!data.more;
              resolve(data.list ?? []);
              if (listRenderType == "createdresult") {
                this.updateGenerateFeedDataSse(data.list);
              }
            } else {
              reject(msg ?? "error qquery code");
              errordone();
            }
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
          })
          .catch((err) => {
            reject(err);
            this[`isloading_${TabDataSuffixMap[listRenderType]}`] = false;
            errordone();
          });
      });
    },
    checkCreateRouter(isOpenGenerate) {
      const createLayoutStore = useCreateLayoutStore();
      createLayoutStore.toggleCreateEditOpenState(isOpenGenerate, true);
      if (Router?.currentRoute?.name != "create") {
        Router.push({
          name: "create",
        });
      }
    },

    goCredits(content, {
      membership_source1,
      membership_source2
    }, isToSubscribe) {
      const userDataStore = useUserDataStore();
      const is_vip = userDataStore?.userInfo?.user?.is_vip;
      if (isToSubscribe) {
        Router.push({
          name: "subscribe",
          query: {
            buy_type: is_vip ? "credits" : "vip",
            membership_source1: membership_source1 ?? 'create',
            membership_source2,
          }
        })
        return
      }
      showActionDialog({
        content: content ?? $$t("create.not_enough_credits_action"),
        cancelLabel: $$t("common.cancel"),
        confirmLabel: $$t("create.buy_credits"),
        // is_vip
        //   ? $$t("create.buy_credits")
        //   : $$t("create.open_vip"),
        confirm: (closeAction) => {
          closeAction();
          // window.open(`${window.location.origin}/credits`, "_blank");
          Router.push({
            name: "subscribe",
            query: {
              buy_type: is_vip ? "credits" : "vip",
              membership_source1: membership_source1 ?? 'create',
              membership_source2,
            },
          });
          firebaseUtils.logClickEvent("credits_useup", {
            is_vip: is_vip ? 1 : 0
          });
        },
        cancel: (closeAction) => {
          if (userDataStore?.isLogin) {
            getInvitePop({}).then((res) => {
              if (res.code == 200) {
                if (res?.data?.pop) {
                  ShowInviteDialog();
                }
              }
            });
          }
          closeAction();
        },
      });
      // message.error({
      //   position: "top",
      //   content: $$t("create.credits_not_enough"),
      // });
    },
    getIsMaxQueueGeneration() {
      return false;
      // doGernerate,doGenerateEasyMode,doEditSongDoneReGenerate,doExtendDoneReGenerate,doRetryGenerate,doRegenerate

      // if (this.curGenerateFeedCount >= 10) {
      //   message.info({
      //     position: "top",
      //     content: $$t("create.max_queue_generation"),
      //   })
      //   return true
      // } else {
      //   return false
      // };
    },

    doGernerate(isInit) {
      const draftDataStore = useDraftDataStore();
      const userDataStore = useUserDataStore();
      return new Promise(async (resolve, reject) => {
        const feedData = await draftDataStore.getGenerateDraftData();
        let prompt = feedData.prompt?.content;
        if (feedData.lyrics?.length < 50) {
          message.info({
            position: "top",
            content: $$t("create.min_50_letters_lyric"),
          });
          reject($$t("create.min_50_letters_lyric"));
          return;
        }
        // if (this.generating) {
        //   message.info({
        //     position: "top",
        //     content: $$t("create.has_song_generating"),
        //   });
        //   reject($$t("create.has_song_generating"));
        //   return;
        // }
        if (this.getIsMaxQueueGeneration("doGernerate")) {
          reject('max queue generation')
          return;
        }
        this.curGenerateFeedCount += 1;
        this.generating = true;
        feedListApi
          .doGenerate(feedData)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              draftDataStore.setLastGenerateDraftData(feedData);
              this.last_generate_call_conn_id = data.conn_id;
              draftDataStore.updateFeedId(data.new_id);
              if (isInit) {
                this.initGenerateFeedData = data.feed;
              } else {
                this.initGenerateFeedData = null;
              }
              this.insertGenerateCard(data.feed);
              this.generating = this.getIsGenerating();

              this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
              userDataStore.refreshUserAccount();

              firebaseUtils.logClickEvent("create_advanced_mode_create", {
                control: feedData.museLeadDataType?.join(","),
                musicid: data.feed?.feed_id,
                type: isInit ? "not_sign_in" : "sign_in",
                lyrics_type: feedData?.isAllAiLyrics ? 'ai' : 'user_edit'
              });


              firebaseUtils.logClickEvent("create_generate", {
                result: 0,
                mode: "2",
                // type: isInit ? "not_sign_in" : "sign_in",
                musicid: data.feed?.feed_id,
                ...(prompt ? { prompt } : {}),
                // control: feedData.museLeadDataType?.join(","),
                lyrics_type: feedData.isAllAiLyrics ? 'ai' : 'user_edit'
              });
              this.checkCreateRouter(false);
              resolve(data);
            } else {
              this.curGenerateFeedCount -= 1;
              this.checkGenerateError(
                {
                  res,
                  isGenerate: true,
                  feedData,
                  mode: "2",
                  membership_source1: "create", membership_source2: 'create_advance'
                },
                prompt ? { prompt } : {}
              );
              this.generating = this.getIsGenerating();
              reject("feed generate failed");
            }
          })
          .catch((err) => {
            this.curGenerateFeedCount -= 1;
            firebaseUtils.logClickEvent("create_generate", {
              result: 2,
              mode: "2",
              ...(prompt ? { prompt } : {}),
            });
            this.generating = this.getIsGenerating();
            message.error({
              position: "top",
              content: $$t("create.generate_failed"),
            });
            reject(err);
          });
      });
    },
    doGenerateEasyMode(isInit, promptText) {
      const draftDataStore = useDraftDataStore();
      const userDataStore = useUserDataStore();
      const createEasyModeStore = useCreateEasyModeStore();

      return new Promise(async (resolve, reject) => {
        const feedData = promptText ? {
          mode: 1,
          prompt: {
            content: promptText
          }
        } : await draftDataStore.getGenerateDraftDataEasyMode();
        // if (this.generating) {
        //   message.info({
        //     position: "top",
        //     content: $$t("create.has_song_generating"),
        //   });
        //   reject($$t("create.has_song_generating"));
        //   return;
        // }

        if (this.getIsMaxQueueGeneration("doGenerateEasyMode")) {
          reject('max queue generation')
          return;
        }

        this.curGenerateFeedCount += 1;
        let prompt = feedData.prompt?.content;

        this.generating = true;
        feedListApi
          .doGenerate(feedData)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              draftDataStore.setLastGenerateDraftData(feedData);
              this.last_generate_call_conn_id = data.conn_id;
              draftDataStore.updateFeedId(data.new_id);
              // if (isInit) {
              //   this.initGenerateFeedData = data.feed;
              // } else {
              //   this.initGenerateFeedData = null;
              // }
              this.insertGenerateCard(data.feed);
              this.generating = this.getIsGenerating();
              this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
              userDataStore.refreshUserAccount();
              const preset = (Object.values(createEasyModeStore.genresPromptmaps_all ?? {}).some((clist) => {
                return clist.some((item) => {
                  return item?.content == prompt
                })
              }) || createEasyModeStore.defaultPromptData?.content == prompt) ? "1" : createEasyModeStore.randomPromptData?.content == prompt ? "3" : "2";

              // const preset = (createEasyModeStore.data_all?.some((item) => item.prompt?.content == prompt) || createEasyModeStore.defaultPromptData?.content == prompt) ? "1" : "2";

              if (!promptText) {
                firebaseUtils.logClickEvent("create_basic_mode_create", {
                  prompt,
                  preset,
                  musicid: data.feed?.feed_id,
                  type: isInit ? "not_sign_in" : "sign_in"
                });
              }
              firebaseUtils.logClickEvent("create_generate", {
                prompt,
                preset,
                mode: "1",
                result: 0,
                musicid: data.feed?.feed_id,
                // type: isInit ? "not_sign_in" : "sign_in"
              });

              this.checkCreateRouter(false);
              resolve(data);
            } else {
              this.curGenerateFeedCount -= 1;
              this.checkGenerateError(
                {
                  res,
                  isGenerate: true,
                  feedData,
                  mode: "1",
                  membership_source1: "create", membership_source2: promptText ? "home_basic" : 'create_basic'
                },
                {
                  prompt,
                }
              );
              this.generating = this.getIsGenerating();
              reject("feed generate failed");
            }
          })
          .catch((err) => {
            this.curGenerateFeedCount -= 1;
            firebaseUtils.logClickEvent("create_generate", {
              mode: "1",
              result: 2,
              prompt: feedData.prompt?.content,
            });
            this.generating = this.getIsGenerating();
            message.error({
              position: "top",
              content: $$t("create.generate_failed"),
            });
            reject(err);
          });
      });
    },
    checkGenerateError({ res, isGenerate, feedData, mode, membership_source1, membership_source2 }, logParams) {
      const museDataStore = useMuseDataStore();
      let machine_audit_state = null;

      switch (res.code) {
        case 6101:
          message.info({
            position: "top",
            content: $$t("create.has_song_generating"),
          });
          break;

        case 6103:
          message.error({
            position: "top",
            content: $$t("create.generate_title_audit_failed"),
          });
          break;

        case 6104:
          message.error({
            position: "top",
            content: $$t("create.generate_lyric_audit_failed"),
          });
          break;

        case 6108:
          message.error({
            position: "top",
            content: $$t("create.generate_text_audit_failed"),
          });
          break;
        case 6314:
          machine_audit_state = 2;
          message.info({
            position: "top",
            content: $$t("create.song_state_auditing"),
          });
          break;

        case 6315:
          machine_audit_state = 4;
          message.info({
            position: "top",
            content: $$t("create.song_state_auditing_failed"),
          });
          this.updateFeedSongItem({
            song_id: item.song_id,
            machine_audit_state,
          });
          break;

        case 6317:
          message.error({
            position: "top",
            content: $$t("create.generate_refer_audit_failed"),
          });
          if (isGenerate) {
            museDataStore.deleteMuseDataByAudit(feedData);
          }
          break;

        case 6310:
          this.goCredits(null, { membership_source2, membership_source1 });
          firebaseUtils.logClickEvent("create_generate", {
            result: 1,
            mode,
            ...(logParams ?? {}),
          });
          break;

        case 6301:
        case 6302:
        case 6308:
          message.error({
            position: "top",
            content: $$t("create.generate_failed"),
          });
          firebaseUtils.logClickEvent("create_generate", {
            result: 2,
          });
          break;

        case 6303: // 不是会员
          this.goCredits($$t("create.multi_generate_task_action_content"), { membership_source1, membership_source2 }, true);
          break;

        case 6323:
          message.error({
            position: "top",
            content: $$t("create.generate_task_upper_limit"),
          });
          break;

        case 6340:
          message.error({
            position: "top",
            content: $$t("create.not_generate_because_not_support_language"),
          });
          break;

        case 9008:
          message.error({
            position: "top",
            content: $$t("common.tooFrequently"),
          })
          break;
        case 10011:
          message.error({
            position: "top",
            content: $$t("create.extend_too_long_generate_failed"),
          })
          break;
        case 10010:
          message.error({
            position: "top",
            content: $$t("create.generate_failed_melody_feed"),
          })
          break;

        default:
          message.error({
            position: "top",
            content: $$t("create.generate_failed"),
          });
          firebaseUtils.logClickEvent("create_generate", {
            result: 2,
            mode,
          });
          break;
      }
    },
    insertGenerateCard(data) {
      const createLayoutStore = useCreateLayoutStore();
      if (
        !this.feedlist_createdresult.some(
          (item) => item.feed_id == data.feed_id
        )
      ) {
        if (!data?.is_accelerated) {
          createLayoutStore.setFeedSubscribeIsRender(true);
        }
        this.feedlist_createdresult.unshift(data);
        this.feedlist_createdresult = [].concat(this.feedlist_createdresult);
      }
    },
    getIsGenerating() {
      return this.curGenerateFeedIdList?.length;
    },
    async doGenerateConnectedSSE(conn_id, feed_id, isQueueCall) {
      // if (this.generating) {
      //   return;
      // }
      const userDataStore = useUserDataStore();
      if (!this.curGenerateFeedIdList.includes(feed_id) || isQueueCall) {
        if (!isQueueCall) {
          this.curSseConnectIdMap[feed_id] = conn_id;
          this.curGenerateFeedIdList.push(feed_id);
        }
        // 避免并发6个限制，同一时刻最多保持两个sse连接，如果 需要多个则排队
        if (this.curGeneratingFeedIdList.length <= 1 && !this.sseGeneratingMap[feed_id] && this.curGenerateFeedIdList.includes(feed_id)) {
          this.generating = true;
          this.curGeneratingFeedIdList.push(feed_id);
          try {
            this.sseGeneratingMap[feed_id] =
              await sseGenerateApi.doSseGetGenerate({
                params: `conn_id=${conn_id}`,
                onOpen(e) { },
                onMessage: (res) => {
                  if (["inf_feed", "pgc_feed"].indexOf(res.event) >= 0) {
                    const feeddata = JSON.parse(res.data ?? "{}");
                    this.updateGenerateCard(feeddata, feed_id);

                    if (feeddata?.state == 4 || feeddata?.state == 6) {
                      userDataStore.refreshUserAccount();
                    }
                    if (feeddata?.state == 3) {
                      let count = 0;
                      feeddata.songs?.forEach((item, index) => {
                        if (item.recall) {
                          count++;
                        }
                      });
                      if (count > 0) {
                        message.error({
                          position: "top",
                          content: $$t("create.generating_some_error").replace(
                            "[count]",
                            count
                          ),
                        });
                        userDataStore.refreshUserAccount();
                      }
                    }
                  } else {
                    if (res.event == "done") {
                      this.curGenerateFeedIdList.splice(
                        this.curGenerateFeedIdList.indexOf(feed_id),
                        1
                      );
                      this.generating = this.getIsGenerating();
                      let noGeneratingFeedIdList =
                        this.curGenerateFeedIdList.filter(
                          (item) => !this.curGeneratingFeedIdList.includes(item)
                        );
                      this.curGeneratingFeedIdList.splice(
                        this.curGeneratingFeedIdList.indexOf(feed_id),
                        1
                      );
                      if (noGeneratingFeedIdList.length) {
                        this.doGenerateConnectedSSE(
                          this.curSseConnectIdMap[noGeneratingFeedIdList[0]],
                          noGeneratingFeedIdList[0],
                          true
                        );
                      }
                      this.curGenerateFeedCount -= 1;
                      this.sseGeneratingMap[feed_id]?.abort();
                      delete this.sseGeneratingMap[feed_id];
                    }
                  }
                },
                onError: (e) => { },
                onClose: (e) => {
                  delete this.sseGeneratingMap[feed_id];
                  this.curGenerateFeedIdList.splice(
                    this.curGenerateFeedIdList.indexOf(feed_id),
                    1
                  );
                  this.curGeneratingFeedIdList.splice(
                    this.curGeneratingFeedIdList.indexOf(feed_id),
                    1
                  );
                  this.generating = this.getIsGenerating();
                },
              });
          } catch (e) {
            this.curGeneratingFeedIdList.splice(
              this.curGeneratingFeedIdList.indexOf(feed_id),
              1
            );
            delete this.sseGeneratingMap[feed_id];
          }
        }
      }
    },
    updateGenerateCard(item, feed_id) {
      item.feed_id = item.feed_id ?? feedid;
      if (this.feedlist_createdresult.some(
        (itemF) => {
          if (itemF.feed_id == feed_id) {
            Object.assign(itemF, item);
            return true;
          }
          return false;
        }
      )) {
        this.feedlist_createdresult = [].concat(this.feedlist_createdresult);
      }
    },
    updateFeedSongItem(data, isRerender = true) {
      const storeDetailDataStore = useStoreDetailData();
      const { song_id, ...obj } = data;
      if (
        this.feedlist_createdresult?.some((itemF) => {
          return itemF.songs?.some((item) => {
            if (item.song_id == song_id) {
              Object.assign(item, obj);
              return true;
            } else {
              return false;
            }
          });
        })
      ) {
        if (isRerender) {
          for (let i = 0; i < TabDataSuffixLists.length; i++) {
            this[`feedlist_${TabDataSuffixLists[i]}`] = [].concat(
              this[`feedlist_${TabDataSuffixLists[i]}`]
            );
          }
        }
      }
      storeDetailDataStore.updateSongDetail(data);
    },

    doRestore(pitem, item) {
      const draftDataStore = useDraftDataStore();
      const createLayoutStore = useCreateLayoutStore();
      return new Promise((resolve, reject) => {
        showActionDialog({
          content: $$t("create.restore_alert"),
          cancelLabel: $$t("create.cancel"),
          confirmLabel: $$t("create.confirm"),
          confirm: (closeAction) => {
            closeAction();
            const closeLoading = message.loading({
              position: "center",
              content: $$t("create.restoring"),
            });
            feedListApi
              .reEditCurFeedDraft({
                feed_id: pitem.feed_id,
                song_id: item.song_id,
              })
              .then((res) => {
                closeLoading?.clear();
                if (res.code == 200) {
                  draftDataStore.setDraftData(res.data, true);
                  if (res.data?.mode == 1) {
                    createLayoutStore.setCreateEditMode("easy");
                  } else {
                    createLayoutStore.setCreateEditMode("hard");
                  }
                  resolve();
                  this.checkCreateRouter(true);
                } else {
                  let machine_audit_state = null;
                  if (res.code == 6314) {
                    machine_audit_state = 2;
                    message.info({
                      position: "top",
                      content: $$t("create.song_state_auditing"),
                    });
                    reject($$t("create.song_state_auditing"));
                  } else if (res.code == 6315) {
                    machine_audit_state = 4;
                    message.info({
                      position: "top",
                      content: $$t("create.song_state_auditing_failed"),
                    });
                    reject($$t("create.song_state_auditing_failed"));
                    this.updateFeedSongItem({
                      song_id: item.song_id,
                      machine_audit_state,
                    });
                  } else {
                    message.error({
                      position: "top",
                      content: $$t("create.restore_failed"),
                    });
                    reject($$t("create.restore_failed"));
                  }
                }
              })
              .catch((err) => {
                closeLoading?.clear();
                console.error(err);
                message.error({
                  position: "top",
                  content: $$t("create.restore_failed"),
                });
                reject($$t("create.restore_failed"));
              });
          },
          cancel(closeAction) {
            closeAction();
            reject("User canceled");
          },
        });
      });
    },

    doRegenerate(pitem, item) {
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        // if (this.generating) {
        //   message.info({
        //     position: "top",
        //     content: $$t("create.has_song_generating"),
        //   });
        //   reject($$t("create.has_song_generating"));
        //   return;
        // }
        if (this.getIsMaxQueueGeneration("doRegenerate")) {
          reject('max queue generation')
          return;
        }

        this.curGenerateFeedCount += 1;
        feedListApi
          .feedCardGenerate({})
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              this.last_generate_call_conn_id = data.conn_id;
              this.insertGenerateCard(data.feed);
              this.generating = this.getIsGenerating();
              this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
              userDataStore.refreshUserAccount();
              resolve(data);
              firebaseUtils.logClickEvent("create_generate", {
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
                musicid: data.feed?.feed_id,
                result: 0,
              });
              this.checkCreateRouter(false);
            } else {
              this.curGenerateFeedCount -= 1;
              this.checkGenerateError({ res, mode: "2", membership_source1: "membership_feature", membership_source2: 'regenerate' });
              this.generating = this.getIsGenerating();
              reject("feedCardGenerate failed");
            }
          })
          .catch((err) => {
            this.curGenerateFeedCount -= 1;
            firebaseUtils.logClickEvent("create_generate", {
              result: 2,
              mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
            });
            this.generating = this.getIsGenerating();
            reject(err);
            message.error({
              position: "top",
              content: $$t("create.generate_failed"),
            });
          });
      });
    },
    doEditSongDoneReGenerate(pitem, item, params) {
      params.start_milliseconds = Math.max(
        0,
        Math.min(item.duration_milliseconds, params.start_milliseconds)
      );

      const userDataStore = useUserDataStore();
      const draftDataStore = useDraftDataStore();
      console.log("doEditSongDoneReGenerate", pitem, item, params);
      return new Promise((resolve, reject) => {
        // if (this.generating) {
        //   message.info({
        //     position: "top",
        //     content: $$t("create.has_song_generating"),
        //   });
        //   reject($$t("create.has_song_generating"));
        //   return;
        // }
        if (this.getIsMaxQueueGeneration("doEditSongDoneReGenerate")) {
          reject('max queue generation')
          return;
        }

        this.curGenerateFeedCount += 1;
        this.generating = true;
        feedListApi
          .feedCardEditedReGenerate(params)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              userDataStore.refreshUserAccount();
              this.last_generate_call_conn_id = data.conn_id;
              draftDataStore.updateFeedId(data.new_id);
              this.insertGenerateCard(data.feed);
              this.generating = this.getIsGenerating();
              this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
              firebaseUtils.logClickEvent("create_generate", {
                result: 0,
                musicid: data.feed?.feed_id,
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
              });
              this.checkCreateRouter(false);
              resolve(data);
            } else {
              this.curGenerateFeedCount -= 1;
              this.checkGenerateError({ res, mode: "2", membership_source1: "membership_feature", membership_source2: 'regenerate' });
              this.generating = this.getIsGenerating();
              reject("feedCard Generate failed");
            }
          })
          .catch((err) => {
            this.curGenerateFeedCount -= 1;
            firebaseUtils.logClickEvent("create_generate", {
              result: 2,
              mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
            });
            this.generating = this.getIsGenerating();
            message.error({
              position: "top",
              content: $$t("create.generate_failed"),
            });
            reject(err);
          });
      });
    },
    doExtendDoneReGenerate(pitem, item, params) {
      params.start_milliseconds = Math.max(
        0,
        Math.max(
          0,
          Math.min(item.duration_milliseconds, params.start_milliseconds) -
          (globalConfig.appname == chinaAppName ? 5000 : 0)

        )
      );
      const userDataStore = useUserDataStore();
      const draftDataStore = useDraftDataStore();
      return new Promise((resolve, reject) => {
        // if (this.generating) {
        //   message.info({
        //     position: "top",
        //     content: $$t("create.has_song_generating"),
        //   });
        //   reject($$t("create.has_song_generating"));
        //   return;
        // }
        if (this.getIsMaxQueueGeneration("doExtendDoneReGenerate")) {
          reject('max queue generation')
          return;
        }
        this.curGenerateFeedCount += 1;
        this.generating = true;
        feedListApi
          .feedCardEditedReGenerate(params)
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              userDataStore.refreshUserAccount();
              this.last_generate_call_conn_id = data.conn_id;
              draftDataStore.updateFeedId(data.new_id);
              this.insertGenerateCard(data.feed);
              this.generating = this.getIsGenerating();
              this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);
              firebaseUtils.logClickEvent("create_generate", {
                result: 0,
                musicid: data.feed?.feed_id,
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
              });
              this.checkCreateRouter(false);
              resolve(data);
            } else {
              this.curGenerateFeedCount -= 1;
              this.checkGenerateError({ res, mode: "2", membership_source1: "membership_feature", membership_source2: 'extend' });
              this.generating = this.getIsGenerating();
              reject("feedCard Generate failed");
            }
          })
          .catch((err) => {
            this.curGenerateFeedCount -= 1;
            firebaseUtils.logClickEvent("create_generate", {
              result: 2,
              mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
            });
            this.generating = this.getIsGenerating();
            message.error({
              position: "top",
              content: $$t("create.generate_failed"),
            });
            reject(err);
          });
      });
    },
    reGenerateSmItem() { },
    doExtend(pitem, item) { },
    doFrom(pitem, item) { },
    doRetryGenerate(pitem, item) {
      const userDataStore = useUserDataStore();
      const draftDataStore = useDraftDataStore();
      return new Promise((resolve, reject) => {
        // if (this.generating) {
        //   message.info({
        //     position: "top",
        //     content: $$t("create.has_song_generating"),
        //   });
        //   reject("has song generating");
        //   return;
        // }
        if (this.getIsMaxQueueGeneration("doRetryGenerate")) {
          reject('max queue generation')
          return;
        }
        this.curGenerateFeedCount += 1;
        this.generating = true;
        feedListApi
          .feedCardRetryGenerate({
            feed_id: pitem.feed_id,
          })
          .then((res) => {
            const { data, code, msg } = res;
            if (code == 200) {
              userDataStore.refreshUserAccount();
              this.last_generate_call_conn_id = data.conn_id;
              this.updateGenerateCard(data.feed);
              this.generating = this.getIsGenerating();
              this.doGenerateConnectedSSE(data.conn_id, data.feed?.feed_id);

              firebaseUtils.logClickEvent("create_generate", {
                result: 0,
                musicid: data.feed?.feed_id,
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
              });
              resolve(data);
            } else {
              this.curGenerateFeedCount -= 1;
              this.checkGenerateError({
                res,
                mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
                membership_source1: "membership_feature",
                membership_source2: 'regenerate'
              });
              this.generating = this.getIsGenerating();
              reject("feedCard Generate failed");
            }
          })
          .catch((err) => {
            this.curGenerateFeedCount -= 1;
            firebaseUtils.logClickEvent("create_generate", {
              result: 2,
              mode: pitem?.mode == 1 ? "1" : pitem?.mode == 0 ? "2" : "",
            });
            this.generating = this.getIsGenerating();
            message.error({
              position: "top",
              content: $$t("create.generate_failed"),
            });
            reject(err);
          });
      });
    },
    doCancelGenerate(pitem, item) {
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        showActionDialog({
          // title: "Title",
          content: $$t("create.cancel_generating_alert"),
          confirmLabel: $$t("create.confirm"),
          cancelLabel: $$t("create.cancel"),
          confirm: (closeAction) => {
            closeAction();
            feedListApi
              .feedCardCancelGenerate({
                feed_id: pitem.feed_id,
              })
              .then((res) => {
                if (res.code == 200) {
                  if (this.curGenerateFeedIdList.includes(pitem?.feed_id)) {
                    this.sseGeneratingMap[pitem.feed_id]?.abort();
                    delete this.sseGeneratingMap[pitem.feed_id];
                    this.curGenerateFeedIdList.splice(
                      this.curGenerateFeedIdList.indexOf(pitem.feed_id),
                      1
                    );
                    this.curGenerateFeedCount -= 1;
                    this.generating = this.getIsGenerating();
                    if (this.initGenerateFeedData?.feed_id == pitem.feed_id) {
                      this.initGenerateFeedData = null;
                    }
                  }
                  let index = this.feedlist_createdresult.findIndex(
                    (itemF) => itemF.feed_id == pitem.feed_id
                  );
                  if (index > -1) {
                    this.feedlist_createdresult.splice(index, 1);
                    this.feedlist_createdresult = [].concat(
                      this.feedlist_createdresult
                    );
                    resolve();
                  }
                  userDataStore.refreshUserAccount();
                } else {
                  message.error({
                    position: "top",
                    content: $$t("create.cancel_generating_failed"),
                  });
                  reject("feedCard Cancel Generate failed");
                }
              })
              .catch((err) => {
                message.error({
                  position: "top",
                  content: $$t("create.cancel_generating_failed"),
                });
                reject(err);
              });
          },
          cancel: (closeAction) => {
            closeAction();
            reject("User canceled");
          },
        });
      });
    },
    doDeleteGenerate(pitem, item) {
      return new Promise((resolve, reject) => {
        // showActionDialog({
        //   // title: "Title",
        //   content: "Are you sure you want to remove the generation?",
        //   confirm: (closeAction) => {
        //     closeAction();
        feedListApi
          .feedCardDeleteGenerate({
            feed_id: pitem.feed_id,
          })
          .then((res) => {
            if (res.code == 200) {
              if (this.curGenerateFeedId == pitem?.feed_id) {
                this.curGenerateFeedId = null;
                this.sseGenerating?.abort();
                this.generating = this.getIsGenerating();
                this.initGenerateFeedData = null;
              }
              let index = this.feedlist_createdresult.findIndex(
                (itemF) => itemF.feed_id == pitem.feed_id
              );
              if (index > -1) {
                this.feedlist_createdresult.splice(index, 1);
                this.feedlist_createdresult = [].concat(
                  this.feedlist_createdresult
                );
                resolve();
              }
            } else {
              message.error({
                position: "top",
                content: $$t("create.cancel_generating_failed"),
              });
              reject("feedCard Cancel Generate failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.cancel_generating_failed"),
            });
            reject(err);
          });
        //   },
        //   cancel: (closeAction) => {
        //     closeAction();
        //     reject("User canceled");
        //   },
        // });
      });
    },
    doDelete(pitem, item) {
      return new Promise((resolve, reject) => {
        showActionDialog({
          // title: "Title",
          content: $$t("create.remove_song_card"),
          confirmLabel: $$t("create.confirm"),
          cancelLabel: $$t("create.cancel"),
          confirm: (closeAction) => {
            closeAction();
            feedListApi
              .feedCardDelete({
                song_id: item.song_id,
              })
              .then((res) => {
                if (res.code == 200) {
                  for (let i = 0; i < TabDataSuffixLists.length; i++) {
                    const listRenderType = TabDataSuffixLists[i];
                    if (this[`feedlist_${listRenderType}`].some(
                      (itemF, index) =>
                        itemF.songs?.some(
                          (itemc, index) => {
                            if (itemc.song_id == item.song_id) {
                              itemF?.songs?.splice(
                                index,
                                1
                              )
                              return true
                            }
                            return false
                          }
                        )
                    )) {
                      this[`feedlist_${listRenderType}`] = [].concat(
                        this[`feedlist_${listRenderType}`]
                      );
                      this[`total_${listRenderType}`] -= 1;
                    }
                  }
                  resolve();
                } else {
                  if (res.code == 6332) {
                    message.error({
                      position: "top",
                      content: $$t("create.like_song_has_been_deleted"),
                    })
                  } else {
                    message.error({
                      position: "top",
                      content: $$t("create.remove_song_card_failed"),
                    });
                  }

                  reject("remove song card failed");
                }
              })
              .catch((err) => {
                message.error({
                  position: "top",
                  content: $$t("create.remove_song_card_failed"),
                });
                reject("remove song card failed");
              });
          },
          cancel: (closeAction) => {
            closeAction();
            reject("User canceled");
          },
        });
      });
    },
    doLike(pitem, item) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      const storeDetailDataStore = useStoreDetailData();
      return new Promise((resolve, reject) => {
        let state = item.is_liked == 1 ? 2 : 1;
        feedListApi
          .favoriteSong({
            song_id: item.song_id,
            state,
          })
          .then((res) => {
            if (res.code == 200) {
              for (let i = 0; i < TabDataSuffixLists.length; i++) {
                const listRenderType = TabDataSuffixLists[i];
                let index = this[`feedlist_${listRenderType}`].findIndex(
                  (itemF) =>
                    itemF.songs?.some((itemc) => itemc.song_id == item.song_id)
                );
                let cindex = -1;
                if (index > -1) {
                  this[`feedlist_${listRenderType}`][index].songs.forEach(
                    (itemc, indexc) => {
                      if (itemc.song_id == item.song_id) {
                        cindex = indexc;
                        itemc.is_liked = res.data.state;
                        itemc.favorite_state = itemc.is_liked;
                      }
                    }
                  );
                  if (listRenderType == "likemysong" && cindex > -1) {
                    this[`feedlist_${listRenderType}`][index].songs.splice(
                      cindex,
                      1
                    );
                    this[`total_${listRenderType}`] -= 1;
                    if (
                      this[`feedlist_${listRenderType}`][index].songs.length ==
                      0
                    ) {
                      this[`feedlist_${listRenderType}`].splice(index, 1);
                    }
                  }
                  this[`feedlist_${listRenderType}`] = [].concat(
                    this[`feedlist_${listRenderType}`]
                  );

                }
              }
              item.is_liked = res.data.state;
              item.favorite_state = item.is_liked;
              storeDetailDataStore.updateSongDetail(item);
              globalPlayDataStore.updatePlayDataItem(item);
              resolve(state);
            } else if (res.code == 6332) {
              message.error({
                content: $$t("create.like_song_has_been_deleted"),
                position: "top",
              });
            } else {
              // message.error({
              //   position: "top",
              //   content: "Save failed",
              // });
              reject(res.code);
            }
          })
          .catch((err) => {
            // message.error({
            //   position: "top",
            //   content: "Save failed",
            // });
            reject(err);
          });
      });
    },
    doMore(pitem, item) { },
    doGoDetail(pitem, item) {
      Router.push({
        name: "songDetail",
        query: {
          id: item.song_id,
        },
      });
    },
    doEditSongTitle(pitem, item) {
      this.updateFeedSongItem(item);
      globalPlayDataStore.updatePlayDataItem(item);
    },
    showRelease(pitem, item) {
      // 首先打开发布弹窗
      this.release.showReleaseModal = true;
      this.release.pitem = pitem;
      this.release.item = item;
    },
    setTargetSongReleaseData(pitem, item, release_info) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      item.release_info = release_info;
      this.updateFeedSongItem(item, true);
      globalPlayDataStore.updatePlayDataItem(item)
    },
    setReleaseData(release_info) {
      this.release.release_info = release_info;
      this.release.item = release_info.item;
      this.release.pitem = release_info.pitem;
    },
    showReleaseFormDialog() {
      // 检查歌曲发布状态，目前默认为true
      this.release.showReleaseModal = false;
      this.release.showReleaseFailModal = false;
      this.release.showReleaseFormModal = true;
    },
    showReleaseResultDialog() {
      this.release.showReleaseModal = false;
      this.release.showReleaseResultModal = true;
    },
    showReleaseAuditFailDialog(callback, reason) {
      this.release.showReleaseFailModal = true;
      this.release.failReason = reason;
      this.release.reReleaseCallback = callback;
    },
    closeReleaseDialog() {
      this.release.showReleaseModal = false;
    },
    closeReleaseFormDialog() {
      this.release.showReleaseFormModal = false;
    },
    closeReleaseResultDialog() {
      this.release.showReleaseResultModal = false;
    },
    closeReleaseFailDialog() {
      this.release.showReleaseFailModal = false;
    },
    async doRelease(data) {
      const res = await this.doUploadImage({
        changed: !!data.coverRaw?.raw,
        pathKey: data.pathKey,
        file: data.coverRaw?.raw,
        // todo: pathpay 回传
      });
      console.log(res);
      const releaseRes = await releaseSong({
        // 1-保存 2-提交发行
        song_id: this.release.item.song_id,
        submit_action: data.submitAction,
        release_info: {
          cover: res.pathKey,
          title: data.songTitle,
          description: data.desc,
          lyricist: data.lyricist,
          composer: data.composer,
          artist_name: data.artistName,
          platform_links: data.personalLinks,
        },
      });
      console.log(releaseRes);
      return releaseRes;
      // 检查歌曲发布状态，目前默认为true
    },
    doShare(pitem, item) {
      return new Promise((resolve, reject) => {
        tools
          .copyToClipboard(
            `${globalConfig.baseOrigin}/song-detail?share_key=${item.share_key}&is_from_share=1`
          )
          .then((res) => {
            // message.success({
            //   content: "Song link copied",
            //   position: "top",
            // });
            resolve();
          })
          .catch(() => {
            reject("Copy failed");
          });
        feedListApi.shareSongReport({
          song_id: item.song_id,
          channel: "link",
        });
        // .then((res) => {
        //   if (res.code == 200) {
        //     resolve();
        //   } else {
        //     reject("share report failed");
        //   }
        // })
        // .catch((err) => {
        //   reject("share report failed");
        // });
      });
    },
    doSureSoldOut(pitem, item) {
      let closeMsg = message.loading({
        position: "center",
        content: $$t("create.success"),
      });
      const globalPlayDataStore = useGlobalPlayDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .saleManageSong({
            song_id: item.song_id,
            type: 0,
          })
          .then((res) => {
            closeMsg && closeMsg.clear();
            if (res.code == 200) {
              item.state = 0;
              this.updateFeedSongItem(item, true);
              globalPlayDataStore.updatePlayDataItem(item);
              resolve();
              message.success({
                position: "top",
                content: $$t("create.save_success"),
              });
            } else {
              let machine_audit_state = null;
              if (res.code == 6314) {
                machine_audit_state = 2;
                message.info({
                  position: "top",
                  content: $$t("create.song_state_auditing"),
                });
                reject("song state auditing");
              } else if (res.code == 6315) {
                machine_audit_state = 4;
                message.info({
                  position: "top",
                  content: $$t("create.song_state_auditing_failed"),
                });
                reject("song state auditing failed");
                this.updateFeedSongItem({
                  song_id: item.song_id,
                  machine_audit_state,
                });
              } else {
                message.error({
                  position: "top",
                  content: $$t("create.save_failed"),
                });
                reject("save failed");
              }
            }
          })
          .catch((err) => {
            closeMsg && closeMsg.clear();

            message.error({
              position: "top",
              content: $$t("create.save_failed"),
            });
            reject(err);
          });
      });
    },
    doUploadImage(imageData, callbackUploadDatal) {
      return new Promise(async (resolve, reject) => {
        if (!imageData.changed && imageData.pathKey) {
          resolve({
            pathKey: imageData.pathKey,
            fullUrl: getObjectStorageFullUrl(imageData.pathKey),
          });
          return;
        }
        try {
          const objectStorageRes =
            await objectStorage.uploadFileToObjectStorage(
              imageData.file,
              (obj) => {
                callbackUploadDatal?.(obj);
              }
            );
          resolve(objectStorageRes);
        } catch (e) {
          reject(e);
        }
      });
    },
    doSureCancelPublish(pitem, item) {
      return new Promise((resolve, reject) => {
        const globalPlayDataStore = useGlobalPlayDataStore();
        const storeDetailDataStore = useStoreDetailData();
        showActionDialog({
          hideCancel: false,
          content: $$t("create.action_unpblish"),
          confirmLabel: $$t("create.unpublish"),
          confirm: (closeAction) => {
            let closeMsg = message.loading({
              position: "center",
              content: $$t("create.applying"),
            });
            feedListApi
              .feedSongPublish({
                song_id: item.song_id,
                type: 2,
              })
              .then((res) => {
                closeMsg && closeMsg.clear();
                if (res.code == 200) {
                  message.error({
                    content: $$t("create.song_unpublish_success"),
                    position: "top",
                  });

                  for (let i = 0; i < TabDataSuffixLists.length; i++) {

                    const listRenderType = TabDataSuffixLists[i];
                    let cindex = -1;
                    let index = this[`feedlist_${listRenderType}`].findIndex(
                      (itemF) =>
                        itemF.songs?.some(
                          (itemc, indexC) => {
                            if (itemc.song_id == item.song_id) {
                              itemc.publish_state = res.data?.publish_state ?? 2;
                              cindex = indexC;
                              return true;
                            }
                          }
                        )
                    );
                    if (index > -1) {
                      if (listRenderType == "publishedmysong" && cindex > -1) {
                        this[`feedlist_${listRenderType}`][index].songs?.splice(
                          cindex,
                          1
                        );
                        this[`total_${listRenderType}`] -= 1;
                        if (!this[`feedlist_${listRenderType}`][index].songs?.length) {
                          this[`feedlist_${listRenderType}`].splice(
                            index,
                            1
                          );
                        }
                      }
                      this[`feedlist_${listRenderType}`] = [].concat(
                        this[`feedlist_${listRenderType}`]
                      );
                    }
                  }
                  item.publish_state = res.data?.publish_state ?? 2;
                  storeDetailDataStore.updateSongDetail(item);
                  globalPlayDataStore.updatePlayDataItem(item);
                  resolve();
                } else {
                  message.info({
                    position: "top",
                    content: $$t("create.song_unpublish_failed"),
                  });
                }
              })
              .catch((err) => {
                closeMsg && closeMsg.clear();
                message.error({
                  content: $$t("create.song_unpublish_failed"),
                  position: "top",
                });
                reject(err);
              });
            closeAction();
          },
          cancel(closeAction) {
            reject("cancel");
            closeAction();
          },
        });
      });
    },
    doSureToSaveSongInfo(pitem, item, imageData, params = {}) {
      return new Promise((resolve, reject) => {
        const globalPlayDataStore = useGlobalPlayDataStore();
        const storeDetailDataStore = useStoreDetailData();
        let closeMsg = message.loading({
          position: "center",
          content: $$t("create.applying"),
        });
        this.doUploadImage(imageData).then((uploadRes) => {
          feedListApi
            .savefeedSongInfo({
              ...params,
              cover: uploadRes?.pathKey,
            })
            .then((res) => {
              closeMsg && closeMsg.clear();
              if (res.code == 200) {
                message.error({
                  content: $$t("create.save_success"),
                  position: "top",
                });

                item.cover = uploadRes?.pathKey;
                item.title = params.title;
                this.updateFeedSongItem(item, true);
                globalPlayDataStore.updatePlayDataItem(item);
                storeDetailDataStore.updateSongDetail(item);
                resolve();
              } else {
                let machine_audit_state = null;
                if (res.code == 6314) {
                  machine_audit_state = 2;
                } else if (res.code == 6315) {
                  machine_audit_state = 4;
                  message.info({
                    position: "top",
                    content: $$t("create.song_state_auditing_failed"),
                  });
                  this.updateFeedSongItem({
                    song_id: item.song_id,
                    machine_audit_state,
                  });
                } else if (res.code == 6121) {
                  message.error({
                    position: "top",
                    content: $$t("create.process_song_info"),
                  });
                } else if (res.code == 6320) {
                  message.error({
                    position: "top",
                    content: $$t("create.song_cover_auditing_failed"),

                  })

                } else if (res.code == 6330) {
                  message.error({
                    position: "top",
                    content: $$t("create.published_song_not_modify"),
                  });
                } else {
                  message.error({
                    content: $$t("create.save_failed"),
                    position: "top",
                  });
                }
                reject("publish failed");
              }
            })
            .catch((err) => {
              closeMsg && closeMsg.clear();
              message.error({
                content: $$t("create.save_failed"),
                position: "top",
              });
              reject(err);
            });
        });
      });
    },
    doSureToPublish(pitem, item, imageData, params = {}) {
      return new Promise((resolve, reject) => {
        const globalPlayDataStore = useGlobalPlayDataStore();
        let closeMsg = message.loading({
          position: "center",
          content: $$t("create.publishing"),
        });
        this.doUploadImage(imageData).then((uploadRes) => {
          feedListApi
            .feedSongPublish({
              ...params,
              cover: uploadRes?.pathKey,
            })
            .then((res) => {
              closeMsg && closeMsg.clear();
              if (res.code == 200) {
                message.error({
                  content: $$t("create.song_publish_success"),
                  position: "top",
                });
                item.publish_state = res.data?.publish_state ?? 1;
                item.cover = uploadRes?.pathKey;
                item.title = params.title;
                item.moods = params.moods;
                item.genres = params.genres;
                this.updateFeedSongItem(item, true);
                globalPlayDataStore.updatePlayDataItem(item);
                resolve();
              } else {
                let machine_audit_state = null;
                if (res.code == 6314) {
                  machine_audit_state = 2;
                } else if (res.code == 6315) {
                  machine_audit_state = 4;
                  message.info({
                    position: "top",
                    content: $$t("create.song_state_auditing_failed"),
                  });
                  this.updateFeedSongItem({
                    song_id: item.song_id,
                    machine_audit_state,
                  });
                } else if (res.code == 6320) {
                  message.error({
                    position: "top",
                    content: $$t("create.song_cover_auditing_failed"),
                  })
                } else if (res.code == 6121) {
                  message.error({
                    position: "top",
                    content: $$t("create.process_song_info"),
                  });
                } else {
                  message.error({
                    content: $$t("create.song_publish_failed"),
                    position: "top",
                  });
                }
                reject("publish failed");
              }
            })
            .catch((err) => {
              closeMsg && closeMsg.clear();
              message.error({
                content: $$t("create.song_publish_failed"),
                position: "top",
              });
              reject(err);
            });
        });
      });
    },
    doSureSale(pitem, item, params = {}) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      let closeMsg = message.loading({
        position: "center",
        content: $$t("create.applying"),
      });
      return new Promise((resolve, reject) => {
        feedListApi
          .saleManageSong({
            song_id: item.song_id,
            ...params,
            type: item.state == 3 ? 2 : 1,
          })
          .then((res) => {
            if (res.code == 200) {
              item.state = res.data?.state ?? 1;
              this.updateFeedSongItem(item, true);
              globalPlayDataStore.updatePlayDataItem(item);
              resolve();
              closeMsg && closeMsg.clear();
              message.success({
                position: "top",
                content: $$t("create.song_sale_under_review"),
              });
            } else {
              closeMsg && closeMsg.clear();
              let machine_audit_state = null;
              if (res.code == 6314) {
                machine_audit_state = 2;
              } else if (res.code == 6315) {
                machine_audit_state = 4;
                message.info({
                  position: "top",
                  content: $$t("create.song_state_auditing_failed"),
                });
                this.updateFeedSongItem({
                  song_id: item.song_id,
                  machine_audit_state,
                });
              } else if (res.code == 6121) {
                message.error({
                  position: "top",
                  content: $$t("create.process_song_info"),
                });
              } else {
                message.error({
                  position: "top",
                  content: $$t("create.applying_failed"),
                });
              }
              reject("sale failed");
            }
          })
          .catch((err) => {
            closeMsg && closeMsg.clear();
            message.error({
              position: "top",
              content: $$t("create.applying_failed"),
            });
            reject(err);
          });
      });
    },
    doDownloadAuto(pitem, item) {
      message.error({
        position: "top",
        content: $$t("create.downloading_wait"),
      });
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .feedCardDownloadQuery({
            song_id: item.song_id,
            t: 2,
          })
          .then((res) => {
            let machine_audit_state = null;
            if (res.code == 6314) {
              machine_audit_state = 2;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing"),
              });
              reject("song state auditing");
            } else if (res.code == 6315) {
              machine_audit_state = 4;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing_failed"),
              });
              this.updateFeedSongItem({
                song_id: item.song_id,
                machine_audit_state,
              });
              reject("song state auditing failed");
            } else if (res.code == 200) {
              userDataStore.refreshUserAccount();
              tools
                .downLoadAtag({
                  useAlink: true,
                  href: item.mp3_url,
                  fileName:
                    item.title.replace(/(\s+)|(\n+)/g, "_") +
                    "." +
                    tools.getFileExtension(href),
                })
                .then((res) => {
                  // message.success({
                  //   content: $$t("create.download_success"),
                  //   position: "top",
                  // });
                });
              resolve();
            } else if (res.data.paid_state == 3) {
              message.error({
                position: "top",
                content: $$t("create.credits_not_enough"),
              });
              reject("credits not enough");
            } else {
              message.error({
                position: "top",
                content: $$t("create.downloading_failed"),
              });
              reject("downloading failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.downloading_failed"),
            });

            reject(err);
          });
      });
    },
    doDownloadMp3(pitem, item) {
      message.info({
        position: "top",
        content: $$t("create.downloading_wait"),
      });
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .feedCardDownloadQuery({
            song_id: item.song_id,
            t: 1,
          })
          .then((res) => {
            let machine_audit_state = null;
            if (res.code == 6314) {
              machine_audit_state = 2;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing"),
              });
              reject("song state auditing");
            } else if (res.code === 6124) {
              message.error({
                content: $$t("create.only_vip_can_download"),
                position: "top",
              });
            } else if (res.code == 6315) {
              machine_audit_state = 4;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing_failed"),
              });
              reject("song state auditing failed");
              this.updateFeedSongItem({
                song_id: item.song_id,
                machine_audit_state,
              });
            } else if (res.code == 200) {
              userDataStore.refreshUserAccount();
              tools
                .downLoadAtag({
                  useAlink: true,
                  href: getObjectStorageFullUrl(res.data.oss_key),
                  fileName:
                    item.title.replace(/(\s+)|(\n+)/g, "_") +
                    "." +
                    tools.getFileExtension(res.data.oss_key),
                })
                .then((res) => {
                  // message.success({
                  //   position: "top",
                  //   content: $$t("create.download_success"),
                  // });
                  resolve();
                })
                .catch((err) => {
                  reject(MediaError);
                });
            } else {
              message.error({
                position: "top",
                content: $$t("create.downloading_failed"),
              });
              reject("downloading failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.downloading_failed"),
            });

            reject("downloading failed");
          });
      });
    },
    doDownloadCopyright(pitem, item) {
      message.info({
        position: "top",
        content: $$t("create.downloading_wait"),
      });
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .feedCardDownloadQuery({
            song_id: item.song_id,
            t: 5,
          })
          .then((res) => {
            if (res.code == 6314) {
              machine_audit_state = 2;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing"),
              });
              reject("song state auditing");
            } else if (res.code === 6124) {
              message.error({
                content: $$t("create.only_vip_can_download"),
                position: "top",
              });
            } else if (res.code == 6315) {
              machine_audit_state = 4;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing_failed"),
              });
              reject("song state auditing failed");
              this.updateFeedSongItem({
                song_id: item.song_id,
                machine_audit_state,
              });
            } else if (res.code == 200) {
              userDataStore.refreshUserAccount();
              tools
                .downLoadAtag({
                  useAlink: true,
                  href: getObjectStorageFullUrl(res.data.oss_key),
                  fileName:
                    $$t("create.ownership_certificate") +
                    "." +
                    tools.getFileExtension(res.data.oss_key),
                })
                .then((res) => {
                  // message.success({
                  //   position: "top",
                  //   content: $$t("create.download_success"),
                  // });
                });
              resolve();
            } else {
              message.error({
                position: "top",
                content: $$t("create.downloading_failed"),
              });
              reject("downloading failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.downloading_failed"),
            });
            reject("downloading failed");
          });
      });
    },
    doDownloadSongAllData(pitem, item) {
      message.info({
        position: "top",
        content: $$t("create.stem_audio_processing"),
      });
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .feedCardDownloadQuery({
            song_id: item.song_id,
            t: 2,
          })
          .then((res) => {
            if (res.code == 6314) {
              machine_audit_state = 2;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing"),
              });
              reject("song state auditing");
            } else if (res.code === 6124) {
              message.error({
                content: $$t("create.only_vip_can_download"),
                position: "top",
              });
            } else if (res.code == 6315) {
              machine_audit_state = 4;
              message.info({
                position: "top",
                content: $$t("create.song_state_auditing_failed"),
              });
              reject("song state auditing failed");
              this.updateFeedSongItem({
                song_id: item.song_id,
                machine_audit_state,
              });
            } else if (res.code == 200) {
              userDataStore.refreshUserAccount();
              if (res.data?.paid_state == 1 || !res.data?.paid_state) {
                message.info({
                  position: "top",
                  content: $$t("create.downloading_stem_audio"),
                });
                tools
                  .downLoadAtag({
                    useAlink: true,
                    href: getObjectStorageFullUrl(res.data.oss_key),
                    fileName:
                      item.title.replace(/(\s+)|(\n+)/g, "_") +
                      "." +
                      tools.getFileExtension(res.data.oss_key),
                  })
                  .then((res) => {
                    resolve();
                    // message.success({
                    //   position: "top",
                    //   content: $$t("create.download_success"),
                    // });
                  })
                  .catch((err) => {
                    reject(false);
                  });
              } else if (res.data?.paid_state == 2) {
                this.goCredits(null, { membership_source1: "membership_feature", membership_source2: "download_instrumental_stem" });
                reject(false);
              } else {
                message.error({
                  position: "top",
                  content: $$t("create.downloading_failed"),
                });
                reject(false);
              }
            } else {
              message.error({
                position: "top",
                content: $$t("create.downloading_failed"),
              });
              reject(false);
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.downloading_failed"),
            });

            reject(err);
          });
      });
    },
    doDownloadVideo(pitem, item, is_download) {
      message.info({
        position: "top",
        content: $$t("create.downloading_video_wait"),
      });
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        feedListApi
          .feedCardGetDownloadVideo({
            song_id: item.song_id,
            is_download,
            // t: 1,
          })
          .then((res) => {
            if (res.code == 6334) {
              message.error({
                position: "top",
                content: res.msg,
              });
              reject("song state auditing failed");
            } else if (res.code == 200) {
              if (!is_download) {
                resolve(res.data.video_url);
                return
              }
              userDataStore.refreshUserAccount();
              tools
                .downLoadAtag({
                  useAlink: true,
                  href: getObjectStorageFullUrl(res.data.video_url),
                  fileName:
                    item.title.replace(/(\s+)|(\n+)/g, "_") +
                    "." +
                    tools.getFileExtension(res.data.video_url),
                })
                .then((res) => {
                  // message.success({
                  //   position: "top",
                  //   content: $$t("create.download_success"),
                  // });
                  resolve();
                })
                .catch((err) => {
                  reject(MediaError);
                });
            } else {
              message.error({
                position: "top",
                content: $$t("create.downloading_failed"),
              });
              reject("downloading failed");
            }
          })
          .catch((err) => {
            message.error({
              position: "top",
              content: $$t("create.downloading_failed"),
            });

            reject("downloading failed");
          });
      });
    },
    doAddPlayList(listRenderType) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      globalPlayDataStore.setCurPlayGroup("feedlist");
      let datas = this[`feedlist_${listRenderType}`].reduce((res, cur) => {
        if (cur.songs?.length) {
          cur.songs.forEach((item) => {
            Object.assign(item, {
              ...item,
              favorite_state: item.is_liked,
            });
            if (!item.recall) {
              res = res.concat(item);
            }
          });
          return res;
        }
        return res;
      }, []);
      globalPlayDataStore.playlist = [];
      globalPlayDataStore.addPlayDatas(datas);
    },
    doTogglePlay(pitem, item, isPlay, listRenderType) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      globalPlayDataStore.setCurPlayGroup("feedlist");

      let datas = this[`feedlist_${listRenderType}`].reduce((res, cur) => {
        if (cur.songs?.length) {
          cur.songs.forEach((item) => {
            Object.assign(item, {
              ...item,
              favorite_state: item.is_liked,
            });
            if (!item.recall) {
              res = res.concat(item);
            }
          });
          return res;
        }
        return res;
      }, []);
      item.favorite_state = item.is_liked;
      globalPlayDataStore.setPlayDatas(datas, item);
    },
    addFeedDataToPlayList(datas) {
      const globalPlayDataStore = useGlobalPlayDataStore();
      if (globalPlayDataStore.curPlayGroup != "feedlist") {
        return;
      }
      let addDatas = datas.reduce((res, cur) => {
        if (cur.songs?.length) {
          cur.songs.forEach((item) => {
            Object.assign(item, {
              ...item,
              favorite_state: item.is_liked,
            });
            if (!item.recall) {
              res = res.concat(item);
            }
          });
        }
        return res;
      }, []);
      globalPlayDataStore.addPlayDatas(addDatas);
    },
    doReportPlayedState(pitem, item) {
      const userDataStore = useUserDataStore();
      return new Promise((resolve, reject) => {
        if (!userDataStore.isLogin) {
          reject("login failed");
          return;
        }
        feedListApi
          .doReportPlayedState({
            song_id: item.song_id,
          })
          .then((res) => {
            if (res.code == 200) {
              this.updateFeedSongItem(
                {
                  song_id: item.song_id,
                  is_played: true,
                },
                false
              );
              resolve();
            } else {
              reject(res?.msg ?? "save failed");
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    doPlayStateReport(pitem, item, play_type) {
      return new Promise((resolve, reject) => {
        feedListApi
          .doPlayStateReport({
            song_id: item?.song_id,
            play_type: play_type,
          })
          .then((res) => {
            if (res.code == 200) {
              resolve();
            } else {
              reject(res?.msg ?? "save failed");
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
});
export default useFeedListStore;
// export const feedlistStore = useFeedListStore()
