import { createApp, nextTick } from "vue";
import Message from "./message.vue";
import { getUuid } from "@/utils/tools.js";
const MessageConfig = {
  allowMultiple: false,
};

const defaultOptions = {
  type: "info",
  maskClosable: false,
  duration: 3000
};

const defaultMessagePosition = {
  info: "top",
  success: "top",
  warning: "top",
  error: "top",
  loading: "center",
};

let MessageOption = {
  ...defaultOptions,
};

const MessageCloseMap = {};

const _closeMessage = () => {
  if (!MessageConfig.allowMultiple) {
    const keys = Object.keys(MessageCloseMap);
    if (keys.length > 0) {
      const key = keys[0];
      MessageCloseMap[key]();
      delete MessageCloseMap[key];
    }
  }
};
const messageDivContainer = document.createElement("div");
messageDivContainer.classList.add("custom-message-container");
document.body.appendChild(messageDivContainer);

const calculateMessagePosition = () => {
  let transY = 20;
  const messageDivContainer = document.querySelector(
    ".custom-message-container"
  );
  let topEl = [...messageDivContainer.childNodes].filter((item) => {
    return item.querySelector(".render-position-top") != null;
  });
  topEl.forEach((item, index) => {
    const messageDiv = item.querySelector(".render-position-top");
    const { width, height } = messageDiv
      .querySelector(".custom-message-content")
      .getBoundingClientRect();
    item.style.setProperty("--customTransY", `${transY}px`);
    transY += height + 12;
  });
  let bottomEl = [...messageDivContainer.childNodes].filter((item) => {
    return item.querySelector(".render-position-bottom") != null;
  });
  bottomEl.reverse();
  transY = 12;
  bottomEl.forEach((item, index) => {
    const messageDiv = item.querySelector(".render-position-bottom");
    const { width, height } = messageDiv
      .querySelector(".custom-message-content")
      .getBoundingClientRect();
    console.log(height);
    item.style.setProperty("--customTransY", `-${transY}px`);
    transY += height + 12;
    console.log(transY);
  });

  let centerEl = [...messageDivContainer.childNodes].filter((item) => {
    return item.querySelector(".render-position-center") != null;
  });
  
  const windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;
  centerEl.forEach((item, index) => {
    const messageDiv = item.querySelector(".render-position-center");
    // messageDiv.style.setProperty("position", "fixed");
    // messageDiv.style.setProperty("left", "0");
    // messageDiv.style.setProperty("bottom", "0");
    // messageDiv.style.setProperty("right", "unset");
    // messageDiv.style.setProperty("top", "unset");
    // messageDiv.style.setProperty("width", `${windowWidth}px`);
    // messageDiv.style.setProperty("height", `${windowHeight}px`);
    // messageDiv.style.setProperty("transform", `translateY(-${windowHeight}px)`);
    // messageDiv.style.setProperty("-webkit-transform", `translateY(-${windowHeight}px)`);
  });
};

window.addEventListener("resize", (event) => {
  nextTick(() => {
    calculateMessagePosition();
  });
});

const showMessage = (options) => {
  const { onClose } = options;
  const uuid = getUuid();
  _closeMessage();
  const curMesageContainer = document.createElement("div");
  curMesageContainer.setAttribute("data-message-uuid", uuid);
  MessageCloseMap[uuid] = () => {
    app.unmount();
    curMesageContainer.remove();
    delete MessageCloseMap[uuid];
  };
  options.onClose = () => {
    MessageCloseMap[uuid]();
    onClose && onClose();
    nextTick(() => {
      calculateMessagePosition();
    });
  };
  options = {
    ...MessageOption,
    ...options,
    visible: true,
    position: options.position ?? defaultMessagePosition[options.type] ?? "top",
    // duration: 30000000
  };
  const app = createApp(Message, options);
  app.mount(curMesageContainer);
  messageDivContainer.appendChild(curMesageContainer);
  nextTick().then(() => {
    calculateMessagePosition();
  });
  return {
    ...showMessage,
    close: () => {
      options.onClose();
    },
  };
};

showMessage.success = (options) => {
  options.type = "success";
  return showMessage(options);
};

showMessage.info = (options) => {
  options.type = "info";
  return showMessage(options);
};

showMessage.warning = (options) => {
  options.type = "warning";
  return showMessage(options);
};

showMessage.error = (options) => {
  options.type = "warning";
  return showMessage(options);
};

showMessage.loading = (options) => {
  options.type = "loading";
  return showMessage(options);
};

showMessage.clear = (options) => {
  const keys = Object.keys(MessageCloseMap);
  if (keys.length > 0) {
    const key = keys[0];
    MessageCloseMap[key]();
  }
};

showMessage.allowMultiple = (allowMultiple) => {
  MessageConfig.allowMultiple = allowMultiple;
};

showMessage.setDefaultOptions = (options) => {
  MessageOption = { ...MessageOption, ...options };
};

showMessage.resetDefaultOptions = (options) => {
  MessageOption = {
    ...defaultOptions,
  };
};

showMessage.copy = (options) => {
  options.type = "copy";
  return showMessage(options);
};

export default showMessage;
