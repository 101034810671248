import { defineStore } from "pinia"
import { updateHeaderConfig } from "@/api/net/config.js"
import tools from "@/utils/tools"
import GlobalConfig from "@/config/config.js"
import apiUser from "@/api/api/apiUser.js"
import router, { isNoAuthenticatedToLogin } from "../router"
import useHomeDataStore from "@/store/homeDataStore.js"
import { funComponentList } from "@/components/functionComp/index"
import { getRealityUser, updateRealityUser } from "@/api/api/apiCommon.js"
import { showLoadingDialog, closeLoadingDialog } from "@/components/functionCallComponent/loading/loading.js"

import useDriverStore from "@/store/driverStore.js"

import useDraftDataStore from "./create/draftDataStore.js"
import useFeedListDataStore from "./create/feedListDataStore.js"
import { closeAllDialog } from "@/components/basic/dialog/dialog.js"
import { closeAllActionDialog } from "@/components/functionCallComponent/action/action.js"
import { $$language } from "@/i18n/i18n.js"
import { firebaseUtils } from "@/utils/analytic/firebaseUtils.js"

import { getCreditInfo } from "@/api/api/apiCredit.js"
import useCreateLayoutStore from "@/store/create/createLayoutStore.js"
import sa from "sa-sdk-javascript"
import message from "@/components/functionCallComponent/message/message.js"
import { getLatestUtmSourceFromCookie } from "@/api/net/config.js"
import { mapBrowserLanguage } from "@/i18n/i18n.js"
const getLocalData = () => {
  const { email } = tools.getUrlAllParams(decodeURIComponent(location.href))
  const localData = localStorage.getItem(GlobalConfig.localStorageGlobalKey) || "{}"
  const { userInfo, authData, config, isLogin, k_sso_token, appLanguage, ..._data } = JSON.parse(localData)
  if (!isLogin) {
    sa.login("")
    return { appLanguage: mapBrowserLanguage(appLanguage) }
  } else {
    sa.login(authData?.user_id?.toString())
  }
  if (!email || (email === userInfo.email && localData?.authData)) {
    return {
      k_sso_token,
      appLanguage: mapBrowserLanguage(appLanguage),
      userInfo,
      isLogin,
      authData,
      config,
      ..._data,
    }
  }
  return {}
}

const useUserDataStore = defineStore({
  id: "userDataStore",
  state: () => {
    const driverStore = useDriverStore()

    const data = {
      credits: 0,
      skyworkInfo: {
        isApp: true,
        isCheckLogin: false,
      },
      appInfo: {
        name: "SkyMusic.Ai",
      },
      isLogin: false, // 是否登陆
      authData: {
        // user_id: 100, // 临时使用
        // oauth_key: "788vaWdtOua0kIuClIkNWMNlcTXSaLNW"
        // app_user_id: "u-6a41a76fa9416d9106b7d26498ae03"
      },
      userInfo: {
        // 全局用户信息
      },
      config: {
        // 全局配置
      },
      appLanguage: "",
      k_sso_token: "",
      userRelData: null,
      bannerList: null,
      activityConfig: null,
      creditInfo: {},
      ...getLocalData(),
    }
    data.authData && updateHeaderConfig(data.authData)
    driverStore.init(data.isLogin)
    return data
  },
  actions: {
    init() {
      // 获取url参数
      return new Promise((resolve, reject) => {
        this.getUserInfo()
          .then((res) => {
            resolve()
            if (res.code != 401) {
              // feedListDataStore.initialize();
              //   draftDataStore.getDraftData();
              this.getUserRealData()
            }

            // this.getConfig()
            //   .then((res) => {
            //     resolve()
            //   })
            //   .catch((err) => {
            //     reject(err)
            //   })
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getUserRealData() {
      return new Promise((resolve, reject) => {
        getRealityUser({}).then((res) => {
          if (res.code == 200) {
            this.setUserRelData(res.data)
          }
        })
      })
    },
    clearAll(isClearLocalData = true) {
      const draftDataStore = useDraftDataStore()
      localStorage.removeItem(GlobalConfig.localStorageGlobalKey)
      this.authData = {}
      this.userInfo = {}
      this.config = {}
      this.clearOther()
      this.updateLoginStatus(false)
      if (isClearLocalData) {
        draftDataStore.clearData()
      }
    },
    clearOther() {
      updateHeaderConfig(this.authData)
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        if (this?.authData?.user_id) {
          apiUser.userGetInfo().then((res) => {
            const { data, code, msg } = res
            if (code == 200) {
              this.credits = data.credits
              this.userInfo = data ?? {}
              this.updateLoginStatus(true)
              resolve(data)
              // this.getBanerConfig()
            } else {
              reject({})
            }
          })
        }
      })
    },
    getConfig() {
      return new Promise((resolve, reject) => {
        apiUser
          .userConfig(this.authData.user_id)
          .then((res) => {
            const { data, code, msg } = res
            if (code == "200") {
              this.config = data ?? {}
              // this.appLanguage = data.app_language ?? "en"
              resolve()
            } else {
              reject({})
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getAll() {
      const { isLogin, authData, userInfo, config, appLanguage, k_sso_token } = this
      return {
        isLogin,
        authData,
        userInfo,
        config,
        appLanguage,
        k_sso_token,
      }
    },
    setCredits(credits) {
      this.credits = credits
    },
    setData(data) {
      Object.assign(this, data)
      localStorage.setItem(GlobalConfig.localStorageGlobalKey, JSON.stringify(this.getAll()))
    },
    updateLoginStatus(isLogin) {
      this.isLogin = isLogin
      localStorage.setItem(GlobalConfig.localStorageGlobalKey, JSON.stringify(this.getAll()))
      if (isLogin) {
        firebaseUtils.logLoginEvent(this?.authData?.user_id ?? 0)
      } else {
        sa.logout()
        const homeDataStore = useHomeDataStore()
        homeDataStore.abortSse()
      }
    },
    updateUserInfo(userInfo) {
      this.userInfo = userInfo
      localStorage.setItem(GlobalConfig.localStorageGlobalKey, JSON.stringify(this.getAll()))
    },
    upDataAuthData(authData) {
      this.authData = authData
      updateHeaderConfig(authData)
      localStorage.setItem(GlobalConfig.localStorageGlobalKey, JSON.stringify(this.getAll()))
      this.init()
    },
    inviteCodePoint(invite) {
      console.log('inviteCodePoint', invite)
      apiUser.inviteCodePoint({ code: invite }).then(() => {
        console.log('inviteCodePoint2', invite)
        localStorage.removeItem("invite_code")
        const newUrl = this.removeURLParameter(window.location.href, "invite_code")
        window.history.replaceState(null, "", newUrl)
      })
    },
    getSignupSource() {
      const createLayoutStore = useCreateLayoutStore()
      const { createEditMode } = storeToRefs(createLayoutStore)
      const pathname = window.location.pathname ?? ""
      if (pathname == "/") {
        return "home_basic"
      } else if (pathname == "/create") {
        if (createEditMode.value == "easy") {
          return "create_basic"
        } else {
          return "create_advance"
        }
      } else {
        return "other"
      }
    },
    removeURLParameter(url, parameter) {
      const urlObj = new URL(url)
      urlObj.searchParams.delete(parameter)
      return urlObj.toString()
    },
    point() {
      apiUser.userPoint().then(() => { })
    },
    checkLoginStatus(params = {}, callback) {
      // rightType, create_type
      return new Promise((resolve, reject) => {
        if (this.isLogin) {
          resolve(this.isLogin)
        } else {
          closeAllDialog()
          closeAllActionDialog()
          funComponentList.ThirdLogin({
            rightType: params?.rightType,
            create_type: params?.create_type,
            source: params?.source,
            handleOk: (str) => {
              callback && callback(this.isLogin)
            },
            handleCancel: () => {
              callback && callback(false)
            },
          })
            // funComponentList.LogInCn({
            //   rightType: params?.rightType,
            //   create_type: params?.create_type,
            //   source: params?.source,
            //   handleOk: (str) => {
            //     callback && callback(this.isLogin)
            //   },
            //   handleCancel: () => {
            //     callback && callback(false)
            //   },
            // })
          resolve()
        }
      })
    },
    setUserRelData(data) {
      this.userRelData = Object.assign({}, this.userRelData ?? {}, data ?? {})
    },
    switchLanguage(curLanguage) {
      if (curLanguage == this.appLanguage) return
      this.appLanguage = curLanguage;
      const draftDataStore = useDraftDataStore();
      draftDataStore.clearAll();
      localStorage.setItem(GlobalConfig.localStorageGlobalKey, JSON.stringify(this.getAll()))
    },
    getBanerConfig() {
      return new Promise((resolve, reject) => {
        apiUser
          .getBanerConfig({})
          .then((res) => {
            const { data, code, msg } = res
            if (code == "200") {
              this.bannerList = data.list ?? []
              this.activityConfig = data.activity_config ?? {}
              resolve()
            } else {
              reject({})
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    refreshUserAccount() {
      return new Promise((resolve, reject) => {
        getCreditInfo()
          .then((res) => {
            if (res.code == 200) {
              const { data } = res
              this.credits = data?.gold || 0
              resolve(this.credits)
            } else {
              reject(res)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    setCreditsInfo(creditsInfo) {
      this.creditInfo = creditsInfo
      this.credits = creditsInfo?.gold || 0
    },
    updateAuthData(_data) {
      this.upDataAuthData(_data.auth)
      this.updateLoginStatus(true)
    },
    async googleLogin(x_auth_token, is_auto = false) {
      console.log('is_auto', is_auto)
      return new Promise(async (resolve, reject) => {
        const { data, code, msg } = await apiUser.userLogin({
          mode: "google",
          ...(is_auto ? { extra_token: "auto_login" } : {}),
          token: x_auth_token,
          source: getLatestUtmSourceFromCookie() ?? "",
          signup_source: this.getSignupSource(),
        })
        if (code === 200) {
          console.log("第三方登录成功")
          this.updateAuthData(data)
          const createLayoutStore = useCreateLayoutStore()
          createLayoutStore.toggleCreateEditOpenState(true)
          if (data?.is_new) {
            this.invite()
          }
          resolve()
        } else if (code == 6401) {
          message.error({
            maskClosable: true,
            position: "top",
            content: "Deleted accounts cannot be registered within 14 days.",
          })
          resolve()
        } else {
          message.error({
            maskClosable: true,
            position: "top",
            content: msg,
          })
          resolve()
        }
      })
    },
    invite() {
      const invite = localStorage.getItem("invite_code") ?? ""
      if (invite) {
        this.inviteCodePoint(invite)
      }
    }
  },
  getters: {
    getAuthData() {
      return this.authData
    },
    getAppInfo() {
      return this.appInfo
    },
  },
})
export default useUserDataStore
