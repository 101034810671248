import { defineStore, setActivePinia } from "pinia";
import { $$language, $$t } from "@/i18n/i18n.js";
import useUserDataStore from "@/store/userDataStore.js";
import layoutConfig from "@/config/layoutConfig";
import globleConfig from "@/config/config.js";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";
import apiCommon from "@/api/api/apiCommon.js";
import dateTimeUtil from "@/utils/dateTime.js";

const useCreateRenderType = defineStore({
  id: "createRenderType",
  state: () => {
    let datasC = {};
    let headSummonCacheData = {};
    try {

      datasC = JSON.parse(window.localStorage.getItem(
        `${globleConfig.appname}_createLayoutData`
      ) ?? "{}");
    } catch (e) {
      datasC = {};
    }
    try {
      headSummonCacheData = JSON.parse(
        localStorage.getItem(
          `${globleConfig.appname}_headSummonvisible_cache_data`
        ) ?? "{}"
      );
    } catch (e) {
      headSummonCacheData = {};
    }
    let headSummonVisible = false;
    if (
      headSummonCacheData?.activityConfig &&
      (headSummonCacheData.headSummonVisible ||
        !headSummonCacheData?.headSummonVisibleTimeScamps ||
        (dateTimeUtil.isPastEndOfDay(new Date(headSummonCacheData?.headSummonVisibleTimeScamps))))
    ) {
      headSummonVisible = true;
    }

    const {
      curOpModule = "createEdit",
      create_createEditOpenState = "min",
      nocreate_createEditOpenState = "min",
      createEditMode = "easy",
      createEditGuidTourVisible = true,
      feedSubscribeIsRender = true
    } = datasC;
    return {
      createRenderType: layoutConfig.renderType,
      curOpModule: curOpModule ?? "createEdit", // createEdit, createResult
      create_createEditOpenState: create_createEditOpenState ?? "max",
      nocreate_createEditOpenState: nocreate_createEditOpenState ?? "min", // max, playermax, min, playermin
      globalPlayerOpened: false,
      createEditMode: createEditMode ?? "easy", // easy hard
      isShowFeedCreateGuid: !createEditGuidTourVisible,
      createEditGuidTourVisible: createEditGuidTourVisible,
      createEditGuidTourCurStep: 0,
      feedSubscribeIsRender,
      activityConfig: headSummonCacheData?.activityConfig ?? null,
      headSummonVisible: headSummonVisible,
      headSummonVisibleTimeScamps: headSummonCacheData?.headSummonVisibleTimeScamps ?? new Date().getTime(),
      curHighlightReferType: null,
    };
  },
  actions: {
    setFeedSubscribeIsRender(isRender) {
      this.feedSubscribeIsRender = isRender
      this.cacheLayoutData();
    },
    setCreateEditGuidTourCurStep(index) {
      this.createEditGuidTourCurStep = index

    },
    setCreateEditGuidTourVisible(visible) {
      this.createEditGuidTourVisible = visible
      this.cacheLayoutData();
    },
    setRenderType(renderType) {
      this.createRenderType = renderType;
      this.cacheLayoutData();
    },
    setCurOpModule(curOpModule, isOpenCreate) {
      this.curOpModule = curOpModule;
      this.toggleCreateEditOpenState(isOpenCreate, true);
    },
    setCreateEditOpenState(createEditOpenState, page) {
      this[`${page}_createEditOpenState`] = createEditOpenState;
      this.cacheLayoutData();
    },
    updataCreateEditOpenState(globalPlayerOpened) {
      // setPlayData call
      const isCreate = window.location.pathname.indexOf("create") > -1;
      this.globalPlayerOpened = globalPlayerOpened;
      // if (isCreate) {
      //   if (this.create_createEditOpenState != "max") {
      //     this.create_createEditOpenState = globalPlayerOpened ? "playermin" : "min";
      //   }
      // } else {
      //   if (this.nocreate_createEditOpenState != "max") {
      //     this.nocreate_createEditOpenState = globalPlayerOpened ? "playermin" : "min";
      //   }
      // }
      if (this.nocreate_createEditOpenState != "max") {
        this.nocreate_createEditOpenState = globalPlayerOpened
          ? "playermin"
          : "min";
      }
      this.create_createEditOpenState = this.nocreate_createEditOpenState;
      this.cacheLayoutData();
    },
    cacheLayoutData() {
      window.localStorage.setItem(
        `${globleConfig.appname}_createLayoutData`,
        JSON.stringify({
          feedSubscribeIsRender: this.feedSubscribeIsRender,
          createEditGuidTourVisible: this.createEditGuidTourVisible,
          createEditMode: this.createEditMode,
          curOpModule: this.curOpModule,
          create_createEditOpenState: this.create_createEditOpenState,
          nocreate_createEditOpenState: this.nocreate_createEditOpenState,
        })
      );
    },
    initCacheLayoutData() {
      const layoutData = window.localStorage.getItem(
        `${globleConfig.appname}_createLayoutData`
      );
      if (layoutData) {
        const {
          createEditGuidTourVisible = true,
          curOpModule = "createEdit",
          create_createEditOpenState = "max",
          nocreate_createEditOpenState = "min",
          createEditMode = "hard",
        } = JSON.parse(layoutData ?? "{}");
        this.createEditMode = createEditMode;
        this.curOpModule = curOpModule;
        this.create_createEditOpenState = create_createEditOpenState;
        this.nocreate_createEditOpenState = nocreate_createEditOpenState;
        this.createEditGuidTourVisible = createEditGuidTourVisible;
      }
    },
    toggleCreateEditOpenState(isOpen, isSwitch) {
      if (isOpen) {
        this.nocreate_createEditOpenState = this.globalPlayerOpened
          ? "playermax"
          : "max";
      } else {
        this.nocreate_createEditOpenState = this.globalPlayerOpened
          ? "playermin"
          : "min";
      }
      this.create_createEditOpenState = this.nocreate_createEditOpenState;
      if (this.createRenderType == "mobile" && !isSwitch) {
        GlobalAudioPlayer.pause(GlobalAudioPlayer.currentUrl);
      }
      this.cacheLayoutData();
    },

    setCurHighlightReferType(referType) {
      this.curHighlightReferType = referType;
    },

    setCreateEditMode(mode) {
      this.createEditMode = mode;
      this.cacheLayoutData();
    },
    setActivityConfig(config) {
      this.activityConfig = config;
      localStorage.setItem(
        `${globleConfig.appname}_headSummonvisible_cache_data`,
        JSON.stringify({
          activityConfig: this.activityConfig,
          headSummonVisible: this.headSummonVisible,
          headSummonVisibleTimeScamps: this.headSummonVisibleTimeScamps,
        })
      );
    },
    setHeadSummonVisible(visible) {
      this.headSummonVisible = visible;
      this.headSummonVisibleTimeScamps = new Date().getTime();
      localStorage.setItem(
        `${globleConfig.appname}_headSummonvisible_cache_data`,
        JSON.stringify({
          activityConfig: this.activityConfig,
          headSummonVisible: this.headSummonVisible,
          headSummonVisibleTimeScamps: this.headSummonVisibleTimeScamps,
        })
      );
    },

    initActivityConfig(activityConfigData) {
      const { activityConfig } = this;
      if (
        activityConfigData?.id &&
        activityConfig?.id !=
        activityConfigData?.id
      ) {
        this.activityConfig = activityConfigData;
        this.headSummonVisible = true;
        localStorage.setItem(
          `${globleConfig.appname}_headSummonvisible_cache_data`,
          JSON.stringify({
            activityConfig: this.activityConfig,
            headSummonVisible: this.headSummonVisible,
            headSummonVisibleTimeScamps: this.headSummonVisibleTimeScamps,
          })
        );
      }
      else {
        this.activityConfig = activityConfigData;
        if (!this.activityConfig?.id || this.activityConfig?.State == 2) { // 代表活动下线了
          this.headSummonVisible = false;
          localStorage.removeItem(
            `${globleConfig.appname}_headSummonvisible_cache_data`,
          );
        }
      }
    }
  },
});

export default useCreateRenderType;
