

export const referI18nKeyMap = {
  refer: 'create.style',
  vocal: 'create.vocal',
  motif: 'create.melody',
  prompt: 'create.prompt',
}

export const referI18nCodeMap = {
  1: 'create.style',
  3: 'create.vocal',
  2: 'create.melody',
  // 5: 'create.prompt',
  4: 'create.style',
}

export const referCodeKeyMap = {
  1: 'refer',
  3: 'vocal',
  2: 'motif',
  // 5: 'prompt',
  4: 'refer',
}

export const referPonitCodeKeyMap = {
  1: 'reference',
  3: 'vocal',
  2: 'melodyidea',
  5: 'description',
  4: 'reference',
}

export default {
  referI18nKeyMap,
  referI18nCodeMap,
  referCodeKeyMap,
  referPonitCodeKeyMap
}