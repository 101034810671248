<template>
  <div>
    <div class="continue-with-third-party-box pointer">
      <GoogleLogin :callback="googleSigninCallback" popup-type="TOKEN">
        <div class="continue-button btn google pointer">
          <div class="google-icon">
            <img src="@/assets/img/ic_google.png" alt="" />
          </div>
          <span class="fn-inline">{{ $$t('login.continue_with_google')}}</span>
        </div>
      </GoogleLogin>
    </div>
    <h1 class="continue-with-third-party-top">
      <img class="img-arrow" src="@/assets/img/ic_left.png" alt="" />
      <span>{{ $$t('login.or') }}</span>
      <img class="img-arrow" src="@/assets/img/ic_right.png" alt="" />
    </h1>
  </div>
</template>

<script setup lang="ts">
import { shallowRef } from "vue"
import message from "../../components/functionCallComponent/message.jsx"
import { $$language, $$t } from "@/i18n/i18n.js";

const emits = defineEmits(["continueWith"])

const state = shallowRef({})

const googleSigninCallback = async (authResponse) => {
  try {
    state.value = {
      x_auth_mode: "google_login",
      x_auth_token: authResponse.access_token,
      // userData: decode_jwt(authResponse.credential),
    }
    emits("continueWith", state.value)
  } catch (error) {
    console.log("googleSigninCallbackErr", error)
    message.error({
      maskClosable: true,
      content: "Sorry we can not connect to your Google account. You can try to use other emails to verify and sign in.",
      position: "top",
    })
  }
}
</script>

<style lang="scss" scoped>
.continue-with-third-party-top {
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  margin: 36px 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    padding: 0 10px;
    color: rgba(255, 255, 255, 1);
  }
  img {
    width: 98px;
    height: 1px;
  }
}
@media screen and (max-width: 768px) {
  .continue-with-third-party-top{
    margin: 16px 0 0px;
  }
}
.continue-with-third-party-box {
  margin-bottom: 10px;
}
.continue-button {
  height: 70px;
  border-radius: 8px;
  opacity: 1;
  box-sizing: border-box;
  border: 1px solid #94adff;
  line-height: 48px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(148,173,255,0.2);
  .google-icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 8px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(148,173,255,0.3);
  }

  .fn-inline {
    font-size: 18px;
    font-weight: 700;
  }
}

.continue-button:hover {
  opacity: 0.8;
}

.g-btn-wrapper {
  width: 100%;
  display: block;
}
</style>
