import { isDev, isProd } from "@/utils/tools.js";
import GlobalAudioPlayer from "@/components/media/audioPlayer/GlobalAudioPlayer.js";

import {
  $$language,
  $$t,
  $$registLanguageChangeListener,
  $$removeLanguageChangeListener,
} from "@/i18n/i18n.js";
import { isMobileDevice } from "@/utils/tools.js"
import { Tools } from "@element-plus/icons-vue";

const globleConfig = {
  appname: import.meta.env.VITE_APPNAME, // "Mureka", YinFeng
  platform: isMobileDevice() ? 'h5' : 'web',
  MAX_LYRIC_COUNT: 2000,
  curObjectStorageType: "OSS", // OSS || COS
  version: "1.0.1",
  localStorageGlobalKey: "skywork_music_ai",
  curObjectStorageExpirTimeForwardTo: 30 * 1000, //mm
  baseObjectStorageUrl: import.meta.env.VITE_COS_BASE_URL,
  baseAssetsUrl:
    import.meta.env.VITE_PUBLIC_PATH == "" || import.meta.env.VITE_PUBLIC_PATH == "/"
      ? window.location.origin
      : import.meta.env.VITE_PUBLIC_PATH,
  baseOrigin: window.location.origin, // import.meta.env.VITE_APP_BASE_ORIGIN,

  switchLanguage: import.meta.env.VITE_SWITCH_LANGUAGE === "true",
};
export const getGuidanceJsonDataUrl = () => {
  return $$language() == "cn"
    ? isProd()
      ? "cos-prod/res/default/yinfeng.json"
      : "cos-test/res/default/yinfeng.json"
    : isProd()
      ? "cos-prod/res/default/mureka.json"
      : "cos-test/res/default/mureka.json";
};

// setTimeout(() => {
//   $$registLanguageChangeListener(() => {
//     globleConfig.MAX_LYRIC_COUNT = $$language() == "en" ? 1500 : 600;
//   });
// })
export default globleConfig;

export const chinaAppName = "YinFeng";
export const overseasAppName = "Mureka";

export const linkMap = {
  waitingList: "www.baidu.com",
  youtube: "https://www.youtube.com/@Melodioai",
  twitter: "https://x.com/Melodioai",
  Discord: "https://discord.gg/aSGcjSYB8A",
  termsofservice:
    "https://plausible-nape-c6d.notion.site/Terms-of-Service-794a545bc9f24996a90bca2801aab8df?pvs=4",
  privacypolicy:
    "https://plausible-nape-c6d.notion.site/Privacy-Policy-eb0e6079c3c34b0c9e5b3eaceb4ae8d8?pvs=4",
};

export const openLink = (link, _isBlank) => {
  window.open(link, _isBlank);
};


export const ModuleOpenStateControl = {
  create: {
    release: false,
    generatingStream: GlobalAudioPlayer.isAudioReadableStreamSupported() && isDev(),
  }
}
