<template>
  <div class="audio-player height-all" ref="refAudioBox" :style="renderStyle">
    <slot>
      <div
        class="audio-player-opbox height-all flex-column"
        :class="`render-disabled--${
          props.disabled
        } render-disabled-extend--${!props.opClickCallBack}`"
      >
        <div
          class="audio-player-title audio-player-title-top olh tl"
          v-if="props.title && props.titlePosition == 'topLeft'"
        >
          {{ props.title }}
        </div>
        <div class="audio-player flex1">
          <div class="playArea flex">
            <div
              class="audio-player-op-switch audio-player-op-switch-prev pointer flex-center"
              v-if="props.switch"
              @click="
                () => {
                  doSwitch('prev');
                }
              "
            >
              <i></i>
            </div>
            <div
              class="audio-player-op boder btn"
              @click="
                () => {
                  changeOp();
                }
              "
            >
              <LoaderCircle
                :loading="renderData.isPlaying && renderData.isLoading"
                :size="renderData.loaderSize"
                :color="renderStyle['--opLoadingColor']"
                :marginSize="2"
                :strokeWidth="2"
              >
                <div class="playBtn apo pointer" v-show="!renderData.isPlaying">
                  <i
                    class="block icon-play"
                    :style="{
                      background: `url(${
                        props.theme == 'dark' ? playIcoDark : playIcoLight
                      })`,
                      backgroundSize: 'cover',
                    }"
                  >
                  </i>
                </div>
                <div class="apo pointer" v-show="renderData.isPlaying">
                  <i
                    class="block icon-pause"
                    :style="{
                      background: `url(${
                        props.theme == 'dark' ? pauseIcoDark : pauseIcoLight
                      })`,
                      backgroundSize: 'cover',
                    }"
                  >
                  </i>
                </div>
                <!-- <div class="apo pointer" v-show="renderData.isPlaying&&renderData.isLoading">
                  <i class="block icon-loading rotate-loading">
                  </i>
                </div> -->
              </LoaderCircle>
            </div>
            <div
              class="audio-player-op-switch audio-player-op-switch-next pointer flex-center"
              v-if="props.switch"
              @click="
                () => {
                  doSwitch('next');
                }
              "
            >
              <i></i>
            </div>
          </div>
          <div class="progress">
            <div class="audio-player-center flex1">
              <div
                class="audio-player-title olh tl"
                v-if="props.title && props.titlePosition == 'centerTopLeft'"
              >
                {{ props.title }}
              </div>
              <div
                class="audio-player-slider"
                :class="{
                  loading: renderData.isLoading,
                }"
                :style="{
                  '--highPointerColor':
                    props.highPosition === null ||
                    props.highPosition == undefined ||
                    props.highPosition > renderData.duration
                      ? 'none'
                      : renderStyle['--progressbarColor'],
                  '--highPointerLeft': getHighPositionPosition(),
                }"
                data-scrollbox
              >
                <el-slider
                  @change="changeProgress"
                  :step="0.01"
                  @input="inputProgress"
                  v-model="renderData.progress"
                  :show-tooltip="false"
                  :format-tooltip="formatTooltip"
                />
                <div
                  v-if="props.highPosition != null"
                  class="audio-player-slider-highlight-pointer"
                  :style="{}"
                ></div>
              </div>
            </div>
            <div class="audio-player-time">
              <span>{{ renderData.startTime }}</span>
              <i
                class="block icon-loading rotate-loading"
                v-if="renderData.isGenerating"
              ></i>
              <span v-else>{{
                renderData.endTime ?? renderData.duration / 1000
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </slot>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  watch,
  defineExpose,
  onMounted,
  onUnmounted,
  reactive,
} from "vue";
import globalAudioPlayer, {
  defaultPersonalizedConfig,
  lastPerContinuousPlaybackTimeData,
} from "./GlobalAudioPlayer.js";
import playIcoDark from "@/assets/img/play-ico.png";
import playIcoLight from "@/assets/img/play-light-ico.png";
import pauseIcoDark from "@/assets/img/pause-ico.png";
import pauseIcoLight from "@/assets/img/pause-light-ico.png";
import Bubble from "@/components/bubble/bubble.vue";

import mediaUtil from "../utils/utils.js";

const props = defineProps({
  renderStyle: Object,
  theme: {
    type: String,
    default: "dark", // light
  },
  titlePosition: {
    type: String,
    default: "topLeft", // centerTopLeft
  },
  title: String,

  volumeOffset: {
    type: Number,
    default: defaultPersonalizedConfig.volumeOffset,
  },
  url: {
    type: String,
    default: "",
  },
  duration: {
    type: Number,
    default: 0,
  },

  from: {
    type: Number,
    default: 0,
  },
  to: {
    type: Number,
    default: 0,
  },
  loop: {
    type: Boolean,
    default: defaultPersonalizedConfig.loop,
  },
  autoToStartIfNotCurrent: {
    type: Boolean,
    default: defaultPersonalizedConfig.autoToStartIfNotCurrent,
  },
  canSeekNotCurrent: {
    type: Boolean,
    default: defaultPersonalizedConfig.canSeekNotCurrent,
  },

  disabled: {
    type: Boolean,
    default: false,
  },
  opClickCallBack: {
    type: Function,
    default: null,
  },
  analyser: Boolean,
  switch: {
    type: Boolean,
    default: false,
  },
  highPosition: {
    type: Number,
    default: null,
  },
  isGenerating: {
    type: Boolean,
    default: false,
  },
});
const renderDefaultStyleMap = {
  dark: {
    "--opLoadingColor": "#000",
    "--opSize": "36px",
    "--opIcoSize": "20px",
    "--opBgColor": "transparent",
    "--opBgHover": "rgba(0,0,0,0.1)",
    "--opMargin": "0",

    "--opSwitchSize": "36px",
    "--opSwitchIcoSize": "20px",
    "--opSwitchBgHover": "rgba(0,0,0,0.1)",

    "--titleColor": "#000",
    "--titleFontSize": "14px",
    "--titleFontWeight": "500",

    "--timeColor": "#000",
    "--timeFontSize": "12px",
    "--timeFontWeight": "400",
    "--timeMinWidth": "36px",

    "--seekbarMargin": "0px 20px 0 10px",
    "--barColor": "rgba(0, 0, 0,  0.2)",
    "--barHeight": "2px",

    "--progressbarColor": "#000",
    "--progressSliderSize": "10px",
    "--progressSliderColor": "#000",
    "--progressSliderHoverColor": "rgba(0,0,0, 0.3)",
  },
  light: {
    "--opLoadingColor": "#fff",
    "--opSize": "36px",
    "--opBgColor": "transparent",
    "--opIcoSize": "20px",
    "--opBgHover": "rgba(0,0,0,0.1)",
    "--opMargin": "0 14px",

    "--opSwitchSize": "36px",
    "--opSwitchIcoSize": "20px",
    "--opSwitchBgHover": "rgba(0,0,0,0.1)",

    "--titleColor": "#fff",
    "--titleFontSize": "14px",
    "--titleFontWeight": "500",

    "--timeColor": "#fff",
    "--timeFontSize": "12px",
    "--timeFontWeight": "400",
    "--timeMinWidth": "36px",

    "--barColor": "rgba(255, 255, 255,  0.2)",
    "--barHeight": "2px",

    "--seekbarMargin": "0px 10px 0 10px",
    "--progressbarColor": "#fff",
    "--progressSliderSize": "10px",
    "--progressSliderColor": "#fff",
    "--progressSliderHoverColor": "rgba(255, 255, 255, 0.3)",
  },
};
const renderStyle = Object.assign(
  {},
  renderDefaultStyleMap[props.theme] || renderDefaultStyleMap["dark"],
  props.renderStyle ?? {}
);

const emits = defineEmits([
  "log",
  "drawEqualizer",
  "syncDuration",
  "syncPlayState",
  "syncLoadingState",
  "syncProgress",
  "switch",
  "play",
  "curPlayEnded",
  "perContinuousPlaybackTimeCallback",
  "update:isGenerating",
]);
const isReady = ref(false); // 是否加载完成，可以开始播放
const isChangeProgress = ref(false);

const refAudioBox = ref(null);

const getLoadingSize = () => {
  return Number(renderStyle["--opSize"].replace("px", ""));
};
const stateData = {};

const renderData = reactive({
  url: props.url,
  startTime: "00:00",
  endTime: "00:00",
  bufferTime: "00:00",
  duration: props.duration,
  progress: 0,
  isPlaying: false,
  isLoading: false,
  isReady: false,
  loaderSize: getLoadingSize(),
  isGenerating: false,
});
const getHighPositionPosition = () => {
  return (props.highPosition ?? 0) > (renderData.duration || 1)
    ? 100
    : ((props.highPosition ?? 0) / (renderData.duration || 1)) * 100 + "%";
};
const formatTooltip = () => {
  return mediaUtil.formatProgressToTime(
    renderData.progress,
    renderData.duration
  );
};

const analyzeStateData = {
  startPosition: 0,
};

// play or pause
const changeOp = (isplay) => {
  if (props.opClickCallBack) {
    props.opClickCallBack();
  }
  if (props.disabled) {
    globalAudioPlayer.pause(renderData.url, true);
    return;
  }
  renderData.isPlaying =
    typeof isplay === "boolean" ? isplay : !renderData.isPlaying;
  if (renderData.isPlaying) {
    globalAudioPlayer.play(renderData.url, true);
    emits("play", renderData.url);
  } else {
    globalAudioPlayer.pause(renderData.url);
  }
};

const doSwitch = (data) => {
  changeOp(false);
  emits("switch", data);
};

// var playStateBeforeSeek = undefined;
const emitsPerContinuousPlaybackTimeCallback = () => {
  if (
    lastPerContinuousPlaybackTimeData.url == renderData.url &&
    lastPerContinuousPlaybackTimeData.startPosition ==
      analyzeStateData.startPosition &&
    lastPerContinuousPlaybackTimeData.time ==
      renderData.progressCp * renderData.duration -
        analyzeStateData.startPosition
  ) {
    return;
  }
  if (
    renderData.progressCp * renderData.duration -
      analyzeStateData.startPosition >
    0
  ) {
    const data = {
      url: renderData.url,
      startPosition: analyzeStateData.startPosition,
      time:
        renderData.progressCp * renderData.duration -
        analyzeStateData.startPosition,
      endPosition: renderData.progressCp * renderData.duration,
    };

    // console.log(
    //   "emitsPerContinuousPlaybackTimeCallback",
    //   data,
    //   lastPerContinuousPlaybackTimeData
    // );
    Object.assign(lastPerContinuousPlaybackTimeData, data);
    emits("perContinuousPlaybackTimeCallback", data);
  }
};

const changeProgress = (value) => {
  stateData.isChangeProgress = false;

  emitsPerContinuousPlaybackTimeCallback();

  renderData.progress = value;
  renderData.progressCp = renderData.progress / 100;
  renderData.startTime = mediaUtil.formatProgressToTime(
    value,
    renderData.duration
  );
  globalAudioPlayer.seek(renderData.url, renderData.progress / 100);
  touchEnd();

  analyzeStateData.startPosition = renderData.duration * renderData.progressCp;
};

const inputProgress = (value) => {
  stateData.isChangeProgress = true;
  renderData.progress = value;
  renderData.startTime = mediaUtil.formatProgressToTime(
    value,
    renderData.duration
  );
};

const init = () => {
  stateData.curPlayerData = globalAudioPlayer.registerPlayUrl(renderData.url, {
    duration: props.duration ?? 0,
    clipStart: props.from,
    clipEnd: props.to,
    personalizedConfig: {
      loop: props.loop,
      autoToStartIfNotCurrent: props.autoToStartIfNotCurrent,
      canSeekNotCurrent: props.canSeekNotCurrent,
    },
    listeners: {
      onDurationChange({
        duration,
        durationTime,
        sourceDuration,
        sourceDurationTime,
      }) {
        renderData.duration = duration;
        renderData.endTime = durationTime;
        if (
          renderData.isGenerating &&
          globalAudioPlayer.audio?.duration == duration &&
          duration > 0 &&
          renderData.url == globalAudioPlayer.currentUrl
        ) {
          renderData.isGenerating = false;
        }
      },
      onPlayStateChange(isPlaying) {
        renderData.isPlaying = isPlaying;
        if (isPlaying) {
          analyzeStateData.startPosition =
            renderData.progressCp * renderData.duration;
        } else {
          emitsPerContinuousPlaybackTimeCallback();
          analyzeStateData.startPosition =
            renderData.progressCp * renderData.duration;
        }
      },
      onLoadingStateChange(isLoading) {
        renderData.isLoading = isLoading;
      },
      onPositionChange: (obj) => {
        const { progress, currentTime, endTime } = obj;
        if (!stateData.isChangeProgress) {
          renderData.startTime = currentTime;
          renderData.endTime = endTime;
          renderData.progress = progress * 100;
        }
        renderData.progressCp = progress;
      },
    },
  });
  globalAudioPlayer.registGlobleListener(
    "curPlayEnded",
    ({ currentUrl, player }) => {
      if (renderData.url == currentUrl || !renderData.url) {
        emits("curPlayEnded", { currentUrl, player });
        emitsPerContinuousPlaybackTimeCallback();
        analyzeStateData.startPosition = 0;
      }
    }
  );
  if (
    stateData.curPlayerData?.player &&
    stateData.curPlayerData?.player?.url == globalAudioPlayer.currentUrl
  ) {
    renderData.isPlaying = stateData.curPlayerData?.player?.isPlaying;
    const { progress, currentTime, endTime } =
      globalAudioPlayer.getPositionData(stateData.curPlayerData?.player);
    const { duration } = globalAudioPlayer.getDuration(
      stateData.curPlayerData?.player
    );
    renderData.duration = duration;
    renderData.progress = progress * 100;
    renderData.progressCp = progress;
    renderData.startTime = currentTime;
    renderData.endTime = endTime;
    emits("syncPlayState", renderData.isPlaying);
    emits("syncProgress", renderData.progress, renderData.startTime);
    emits("syncDuration", renderData.duration);
  }
  // console.log("init🍎苹果", stateData.curPlayerData);
};

const handleTouchStart = () => {};

const touchEnd = () => {
  // if (jsBridge.inTiangongApp) {
  //   jsBridge("event.requestGesture", { eventTye: "horizontal", action: "release" })
  // }
};

watch(
  () => renderData.isPlaying,
  (newValue) => {
    emits("syncPlayState", renderData.isPlaying);
  }
);
watch(
  () => renderData.isLoading,
  (newValue) => {
    emits("syncLoadingState", renderData.isLoading);
  }
);
watch(
  () => renderData.progress,
  (newValue) => {
    emits("syncProgress", renderData.progress, renderData.startTime);
  }
);

watch(
  () => props.disabled,
  (newValue) => {
    if (props.disabled) {
      console.log("props.disabled", props.disabled);
      globalAudioPlayer.pause(renderData.url, true);
    }
  }
);

watch(
  () => {
    return props.duration;
  },
  () => {
    renderData.duration = props.duration;
    renderData.endTime = mediaUtil.formatTime(props.duration);
  }
);

watch(
  () => {
    return props.url;
  },
  () => {
    renderData.url = props.url;
    init();
  }
);

watch(
  () => {
    return props.loop;
  },
  () => {
    globalAudioPlayer.setLoop(renderData.url, props.loop);
  }
);

watch(
  () => {
    return props.isGenerating;
  },
  (newValue) => {
    renderData.isGenerating = newValue;
  }
);

watch(
  () => {
    return renderData.isGenerating;
  },
  (newValue) => {
    emits("update:isGenerating", newValue);
  }
);

onMounted(() => {
  renderData.url = props.url;
  renderData.duration = props.duration;
  renderData.endTime = mediaUtil.formatTime(props.duration);
  renderData.loaderSize = getLoadingSize();
  renderData.isGenerating = props.isGenerating;

  init();
  stateData.playerCacheData = {
    uuid: globalAudioPlayer.getUuid(),
    pause() {
      changeOp(false);
    },
    getContainer() {
      return refAudioBox.value;
    },
  };
});

onUnmounted(() => {
  globalAudioPlayer.releasePlayer(stateData.curPlayerData);
});

defineExpose({
  changeOp,
  changeProgress,
});
</script>
<style lang="scss">
.audio-player {
  .playArea {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  .audio-player-opbox {
  }
  .audio-player {
    align-items: center;
  }
  .audio-player-op {
    height: var(--opSize);
    width: var(--opSize);
    min-width: var(--opSize);
    border-radius: var(--opSize);
    margin: var(--opMargin);

    display: flex;
    align-items: center;
    justify-content: center;
    &.boder {
      border: 1.71px solid #fff;
    }
  }
  /*.audio-player-op:hover {
    background-color: var(--opHoverBg);
  }*/
  .apo.pointer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--opSize);
    background: var(--opBgColor);
  }
  i.block {
    width: var(--opIcoSize);
    height: var(--opIcoSize);
  }
  i.icon-loading.block {
    width: calc(var(--opIcoSize) - 6px);
    height: calc(var(--opIcoSize) - 6px);
  }
  /*.apo.pointer:hover{
    background: var(--opBgHover);
  }*/

  .audio-player-op.play {
    background: url(../../../assets/img/player/play.svg);
    background-size: 100% 100%;
  }
  .audio-player-op.stop {
    background: url(../../../assets/img/pause-ico.png);
    background-size: 100% 100%;
  }
  .audio-player-op.ratate-loading {
    height: 20px;
    width: 20px;
  }

  .audio-player-op-switch {
    height: var(--opSwitchSize);
    width: var(--opSwitchSize);
    min-width: var(--opSwitchSize);
    border-radius: var(--opSwitchIcoSize);
    margin-right: 0;
    i {
      width: var(--opSwitchIcoSize);
      height: var(--opSwitchIcoSize);
    }
    &:hover {
      background-color: var(--opSwitchHoverBg);
    }
    &.audio-player-op-switch-prev {
      i {
        background: url(../../../assets/img/switch-prev-ico.png);
        background-size: 100% 100%;
      }
    }
    &.audio-player-op-switch-next {
      margin-right: 8px;
      i {
        background: url(../../../assets/img/switch-next-ico.png);
        background-size: 100% 100%;
      }
    }
  }
  .audio-player-title {
    font-size: var(--titleFontSize);
    font-weight: var(--titleFontWeight);
    color: var(--titleColor);
    line-height: var(--titleFontSize);
    padding: 0 0 5px 0;
    max-width: 100%;
  }
  .audio-player-title-top {
    padding: 0 0 1px 6px;
  }
  .audio-player-time {
    display: flex;
    justify-content: space-between;
    font-family: Source Han Sans;
    margin-top: 2px;
    font-size: var(--timeFontSize);
    font-weight: var(--timeFontWeight);
    color: var(--timeColor);
    min-width: var(--timeMinWidth);
    line-height: calc(var(--timeFontSize) * 1.5);
    i.icon-loading.block {
      width: calc(var(--timeFontSize) * 1.5);
      height: calc(var(--timeFontSize) * 1.5);
      background: url(@/assets/img/loading.png);
      background-size: 100% 100%;
    }
  }
  .audio-player-center {
    margin-left: 4px;
    margin: var(--seekbarMargin);
  }
  .var-slider__horizontal {
    .var-slider__horizontal-block {
      height: unset;
    }
    .var-slider__horizontal-track {
      height: calc(var(--barHeight) + 10px);
      border-radius: 4px 4px 4px 4px;
      box-sizing: content-box;
      background-color: transparent;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .var-slider__horizontal-track-background {
      content: "";
      display: block;
      height: var(--barHeight);
      width: 100%;
      border-radius: 4px 4px 4px 4px;
      box-sizing: content-box;
      background-color: var(--barColor);
    }
    .var-slider__horizontal-track-fill {
      height: var(--barHeight);
      position: absolute;
      top: 5px !important;
      border-radius: 4px 4px 4px 4px;
      background: var(--progressbarColor);
    }
    .var-slider__horizontal-thumb-ripple {
      width: calc(var(--progressSliderSize) + 10px);
      height: calc(var(--progressSliderSize) + 10px);
      opacity: 1;
      .var-hover-overlay--hovering {
        opacity: 1;
      }
    }

    .var-slider__horizontal-thumb-block {
      width: var(--progressSliderSize);
      height: var(--progressSliderSize);
      background: var(--progressSliderColor);
      transition: unset !important;
      vertical-align: unset;
      border: none;
    }
    .var-slider__horizontal-thumb-ripple--active {
      background: var(--progressSliderHoverColor);
    }
  }
  .audio-player-slider {
    position: relative;
    .el-slider__runway:after {
      content: "";
      position: absolute;
      height: 4px;
      width: 4px;
      border-radius: 4px;
      top: 5px;
      left: var(--highPointerLeft);
      background-color: var(--highPointerColor);
    }

    .el-slider {
      height: unset;
    }
    .el-slider__runway {
      height: calc(var(--barHeight) + 10px);
      border-radius: 4px 4px 4px 4px;
      box-sizing: content-box;
      background-color: transparent;
      position: relative;
    }
    .el-slider__runway::before {
      margin-top: 6px;
      content: "";
      display: block;
      height: var(--barHeight);
      width: 100%;
      border-radius: 4px 4px 4px 4px;
      box-sizing: content-box;
      background-color: var(--barColor);
    }
    .el-slider__button {
      width: var(--progressSliderSize);
      height: var(--progressSliderSize);
      background: var(--progressSliderColor);
      transition: unset !important;
      vertical-align: unset;
      border: none;
    }
    .el-slider__button-wrapper {
      top: calc(var(--barHeight) / 2 + 6px - 12px);
      height: 24px;
      width: 24px;
      line-height: 24px;
      z-index: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .el-slider__bar {
      height: var(--barHeight);
      position: relative;
      top: calc(0px - var(--barHeight));
      border-radius: 4px 4px 4px 4px;
      background: var(--progressbarColor);
    }
  }
  .audio-player-slider.loading {
    .el-slider__runway {
      // animation: 1s lineto linear infinite;
    }
  }
  .render-disabled--true {
    opacity: 0.5;
    .audio-player-op {
      cursor: not-allowed;
    }
    .audio-player-op:hover {
      background-color: transparent;

      .apo.pointer:hover {
        cursor: not-allowed;
        background: transparent;
      }
    }
  }
  .render-disabled-extend--false {
    opacity: 1;
    .audio-player-op {
      cursor: unset;
      opacity: 0.8;
    }
    .audio-player-center {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .audio-player-time {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
</style>
