import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import tools from "@/utils/tools.js";
import globalConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import sseGenerateApi from "@/api/api/apiSse.js";
import message from "@/components/functionCallComponent/message.jsx";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";
import { $$t } from "@/i18n/i18n.js";

import ShowActionDialog from "@/components/functionCallComponent/action/action.js";
import useCreateEasyModeStore from "@/store/create/createEasyModeStore.js";
import useFeedListStore from "./feedListDataStore.js";
import useLyricDataStore from "./lyricDataStore.js";
import useMuseDataStore from "./museDataStore.js";
import userReferMusicStore from "./referMusicStore.js";
// import useDraftDataStore from './draftDataStore.js';
// const draftDataStore = useDraftDataStore();
// const lyricDataStore = useLyricDataStore();
// const museDataStore = useMuseDataStore();

const useDraftDataStore = defineStore({
  id: "draftDataStore",
  state: () => {
    return {
      draftData: {},
      feed_id: "",
      lastGenerateDraftData: null,
    };
  },
  actions: {
    setDraftData(data, isReset = false) {
      // mode: 0-默认 1-ez 
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      this.draftData = data ?? {};
      this.feed_id = data?.feed_id;
      const createEasyModeStore = useCreateEasyModeStore();
      if(data?.mode == 1) {
        createEasyModeStore.initEasyPromptData(data, isReset);
      }else{
        lyricDataStore.initLyricData(data, isReset);
        museDataStore.initMuseData(data);
      }
    },

    geLocalDraftData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const createEasyModeStore = useCreateEasyModeStore();
      return {
        museData:museDataStore.getMuseData(),
        lyricData: lyricDataStore.getLyricData(),
        easyModeData: createEasyModeStore.getEasyModeData(),
      }

    },
    setLocalDraftData(data) {
      const createEasyModeStore = useCreateEasyModeStore();
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      lyricDataStore.setLyricData(data.lyricData);
      museDataStore.setMuseData(data.museData);
      createEasyModeStore.setEasyModeData(data.easyModeData);
    },

    cacheLocalDraftData() {
      // localStorage.setItem(
      //   `${globalConfig.appname}_draftData`,
      //   JSON.stringify(this.geLocalDraftData())
      // )
    },
    getLocalCacheDraftData() {
      try {
        return JSON.parse(localStorage.getItem(`${globalConfig.appname}_draftData`));
      }catch (e) {
        return null;
      }
    },
    getDraftData(params = {}) {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      this.closeActionDialogCallback && this.closeActionDialogCallback();
      this.closeActionDialogCallback = null;
      return new Promise((resolve, reject) => {
        feedListApi
          .getDraftData(params)
          .then((res) => {
            const { code, data, msg } = res;
            if (code == 200) {
              this.draftData = data ?? {};
              this.feed_id = data?.feed_id;
              lyricDataStore.initLyricData(data);
              museDataStore.initMuseData(data);
              useFeedListStore().init({});
              resolve();
            } else {
              if (code == 401) {
                reject("Authentication failed");
                return;
              }

              this.closeActionDialogCallback = ShowActionDialog({
                content: $$t("create.draft_failed"),
                width: "500px",
                confirmLabel: $$t("create.retry"),
                hideCancel: true,
                showCloseBtn: true,
                close: (closeAction) => {
                  this.closeActionDialogCallback();
                  this.closeActionDialogCallback = null;
                },
                confirm: async (closeAction) => {
                  this.closeActionDialogCallback();
                  this.closeActionDialogCallback = null;
                  try {
                    await this.getDraftData(params);
                    resolve();
                  } catch (e) {
                    reject('get draft data failed');
                  }
                },
              });
            }
          })
          .catch((e) => {
            this.closeActionDialogCallback = ShowActionDialog({
              content: $$t("create.draft_failed"),
              width: "500px",
              confirmLabel: $$t("create.retry"),
              hideCancel: true,
              showCloseBtn: true,
              close: (closeAction) => {
                this.closeActionDialogCallback();
                this.closeActionDialogCallback = null;
              },
              confirm: async (closeAction) => {
                this.closeActionDialogCallback();
                this.closeActionDialogCallback = null;
                this.getDraftData(params);
              },
            });
            reject('get draft data failed');
          });
      });
    },
    resetDraftData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const createEasyModeStore = useCreateEasyModeStore();
      lyricDataStore.initLyricData({});
      museDataStore.initMuseData({});
      createEasyModeStore.initEasyPromptData({});
    },
    clearAll() {
      this.resetDraftData(true);
    },
    updateFeedId(newDraftId) {
      this.feed_id = newDraftId;
    },
    setLastGenerateDraftData(feedData) {
      this.lastGenerateDraftData = feedData;
    },
    getLocalDraftData() {},
    clearData() {
      useFeedListStore().clearData();
      this.clearAll();
    },
    async getGenerateDraftData() {
      const lyricDataStore = useLyricDataStore();
      const museDataStore = useMuseDataStore();
      const museData = await museDataStore.getMuseGenerateData();
      return {
        // feed_id: this.feed_id,
        mode: 0,
        ...museData,
        ...(await lyricDataStore.getLyricData()),
      };
    },
    async getGenerateDraftDataEasyMode() {
      const createEasyModeStore = useCreateEasyModeStore();
      return {
        mode: 1,
        prompt: createEasyModeStore.getEasyPromptData(),
      };
    },
  },
});
export default useDraftDataStore;
// export const draftDataStore = useDraftDataStore()
