import { defineStore } from "pinia";
import { ref, nextTick } from "vue";
import tools from "@/utils/tools.js";
import globalConfig from "@/config/config.js";
import feedListApi from "@/api/api/feedListApi.js";
import message from "@/components/functionCallComponent/message.jsx";
import { getObjectStorageFullUrl } from "@/lib/objectStorage/objectStorage.js";

const usePresetSourceDataStore = defineStore({
  id: "presetSourceDataStore",
  state: () => {
    return {
      presetSourceData: null,
    };
  },
  actions: {
    getPresetSourceData(isRefresh = false) {
      return new Promise((resolve, reject) => {
        if (this.presetSourceData && !isRefresh) {
          resolve(this.presetSourceData)
          return;
        }
        feedListApi.getPresetSourceData().then((res) => {
          if (res.code === 200) {
            this.presetSourceData = res.data;
            resolve(this.presetSourceData)
          } else {
            reject(res)
          }
        }).catch((err) => {
          reject(err)
        })
      })
    }
  },
});
export default usePresetSourceDataStore;
// export const presetSourceDataStore = usePresetSourceDataStore()
