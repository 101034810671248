<template>
  <div class="continue-with-third-party tc">
    <h1 class="continue-with-third-party-top">
      <img class="img-arrow" src="@/assets/img/ic_left.png" alt="" />
      <span>{{ $$t('login.or') }}</span>
      <img class="img-arrow" src="@/assets/img/ic_right.png" alt="" />
    </h1>
    <div class="continue-with-third-party-box pointer">
      <GoogleLogin :callback="googleSigninCallback" popup-type="TOKEN">
        <div class="continue-button btn google pointer">
          <div class="google-icon">
            <img src="@/assets/img/login/ic-google.png" alt="" />
          </div>
        </div>
      </GoogleLogin>
      <img class="login-img" @click="discordLogin" src="@/assets/img/login/ic-discord.png" alt="" />
      <img class="login-img" @click="signInAppleLogin" src="@/assets/img/login/ic-apple.png" alt="" />
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  computed,
  readonly,
  reactive,
  watch,
  defineExpose,
  isRef,
  unref,
  customRef,
  toRef,
  toRefs,
  shallowReactive,
  shallowRef,
  isReadonly,
  isReactive,
  isProxy,
  toRaw,
  markRaw,
  shallowReadonly,
  provide,
  inject,
  onMounted,
  onUpdated,
  onUnmounted,
  onBeforeMount,
  onBeforeUpdate,
  onBeforeUnmount,
  onErrorCaptured,
  onRenderTracked,
  onRenderTriggered,
  onActivated,
  onDeactivated,
  onServerPrefetch,
} from "vue"
// import Google, { decode_jwt } from "@/utils/googleLogin"
import message from "../../components/functionCallComponent/message.jsx"
import { $$language, $$t } from "@/i18n/i18n.js";

const state = shallowRef({})
const props = defineProps({})
const emits = defineEmits(["continueWith"])

const clickFun = () => {
  document.querySelector(".g-btn-wrapper").click()
}
const callback = (response) => {
  // This callback will be triggered automatically
  // if one single Google account is already logged in
  state.value = {
    x_auth_mode: "google_login",
    x_auth_token: response.id_token,
    // userData: decode_jwt(authResponse.credential),
  }
  emits("continueWith", state.value)
}
const googleSigninCallback = async (authResponse) => {
  try {
    state.value = {
      x_auth_mode: "google_login",
      x_auth_token: authResponse.access_token,
      // userData: decode_jwt(authResponse.credential),
    }
    emits("continueWith", state.value)
  } catch (error) {
    message.error({
      maskClosable: true,
      content: $$t('login.sorry_we_can_not'),
      position: "top",
    })
  }
}

// FB 登录相关
const loadFacebookSDK = async () => {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: "1454555968623317",
      xfbml: true,
      version: "v3.2",
    })
    window.FB.AppEvents.logPageView()
  }
  ;(function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0]
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    js.src = "https://connect.facebook.net/en_US/sdk.js"
    fjs.parentNode.insertBefore(js, fjs)
  })(document, "script", "facebook-jssdk")
}

const waitForInit = async () => {
  return new Promise((res) => {
    const hasFbLoaded = () => {
      if (window.FB) {
        console.log("FB SDK loaded")
        res()
      } else {
        setTimeout(hasFbLoaded, 300)
      }
    }
    hasFbLoaded()
  })
}

const fbLogin = () => {
  // 目前只配置了 public_profile
  window.FB.login(checkLoginState, { scope: "public_profile" })
  window.FB.login(function (response) {
    if (response.authResponse) {
      FB.api("/me", function (response) {
        console.log("Good to see you, " + response.name + ".", response)
        emits("continueWith", {
          type: "facebook",
          x_auth_mode: "facebook_login",
          authResponse: response,
        })
      })
    } else {
      console.log("User cancelled login or did not fully authorize.")
    }
  })
}

const discordLogin = () => {
  const clientId = "1271026704529362995" // 从 Discord 应用获取
  const url = window.location.pathname == "create" ? `${window.location.origin}/create` : `${window.location.origin}/`
  const redirectUri = encodeURIComponent(url)
  const discordAuthUrl = `https://discord.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=identify`
  // 重定向到 Discord 授权页面
  window.location.href = discordAuthUrl
}

const initAppleLogin = () => {
  if (AppleID?.auth) {
    const nonce = Math.random().toString(36).substr(2, 10)
    AppleID.auth.init({
      clientId: "ai.mureka.service",
      redirectURI: `${window.location.origin}/api/notify/ios`,
    })
  }
}

const signInAppleLogin = () => {
  try {
    AppleID.auth.signIn()
  } catch (error) {
    console.error(" Apple Sign In Error: ", error)
  }
}

const checkLoginState = () => {
  console.log("checkLoginState")
  window.FB.getLoginStatus(statusChangeCallback)
}

const fbSigninData = ref()

const statusChangeCallback = (response) => {
  fbSigninData.value = response
  // if (response.status === "connected") {
  //   this.handleResponse(response.authResponse);
  // } else {
  //   this.handleError(response);
  // }
}

onMounted(async () => {
  loadFacebookSDK()
  initAppleLogin()
  await waitForInit()
})
</script>
<style lang="scss">
.continue-with-third-party {
  .continue-with-third-party-top {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      line-height: 22px;
      padding: 0 10px;
      color: rgba(255, 255, 255, 1);
    }
    img {
      width: 98px;
      height: 2px;
    }
  }
  .continue-with-third-party-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-bottom: 20px;
    .login-img {
      width: 48px;
      height: 48px;
      display: block;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .continue-button {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .google-icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 48px;
      img {
        width: 48px;
        height: 48px;
      }
    }
    &:hover {
      // border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  .continue-button:hover {
    // @include hover-opacity;
    opacity: 0.8;
  }
  .g-btn-wrapper {
    // width: 100%;
    display: block;
  }
}
</style>
