import axiosIS from '@/api/net/axios.js';
import { baseUrl } from '@/config/apiConfig.js';

export function saveReferenceMusicTmp(data = {}, options = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/parse/music_link`,
    data,
    { method: 'post', preParams: data, ...options }
  );
}

// 加入购物车
export function addCart(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/cart/add`,
    data,
    { method: 'post', preParams: data }
  );
}

// 获取枚举
export function miscConfig(data = {}) {
  return axiosIS(
    `${baseUrl}/api/misc/config`,
    data,
    { method: 'get' }
  );
}

// 订单支付
export function payOrder(channel,data = {}) {
  return axiosIS(
    `${baseUrl}/api/payment/${channel}/purchase-entry`,
    data,
    { method: 'get' }
  );
}

// 检查订单
// https://rg975ojk5z.feishu.cn/wiki/PugBw1pr3iDzZWkKBaicmIbanwc
export function checkSongOrder(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/order/check`,
    data,
    { method: 'post' }
  );
}

// 获取用户真实信息
export function getRealityUser(data = {}) {
  return axiosIS(
    `${baseUrl}/api/user/reality`,
    data,
    { method: 'get' }
  );
}

// 更新用户真实信息
export function updateRealityUser(data = {}) {
  return axiosIS(
    `${baseUrl}/api/user/reality/update`,
    data,
    { method: 'post' }
  );
}

// 举报  
export function reportMusic(data = {}) {
  return axiosIS(
    `${baseUrl}/api/misc/report`,
    data,
    { method: 'post' }
  );
}


// 活动配置
export function getActivityConfig(data = {}) {
  return axiosIS(
    `${baseUrl}/api/pgc/activity/config`,
    data,
    { method: 'get' }
  );
}




export default {
  saveReferenceMusicTmp,
  addCart,
  miscConfig,
  reportMusic,
  getActivityConfig
}
