<template>
  <Dialog
    @close="onCloseClick"
    :hideCloseBtn="props.showCloseBtn != true"
    :visible="props.visible"
    :title="props.title"
    :height="props.height"
    :width="props.width"
    titleAlign="center"
    background="rgba(99, 113, 132, 0.5)"
    :customClass="`${props.customClass} action-dialog`"
    :tag="props.tag"
  >
    <div class="action-dialog">
      <p class="action-dialog-text tc">
        <template v-for="(item,index) in props.content.split('\n')" :key="index">
          <p>
             {{item}}
          </p>
        </template>
      </p>
      <div
        class="action-dialog-btns flex"
        :class="{
          nocancel: props.hideCancel,
        }"
      >
        <div
          class="action-dialog-btn action-dialog-cancel flex1"
          v-if="!props.hideCancel"
        >
          <Button
            :renderStyle="{
              '--padding': '0',
              '--width': '100%',
              '--height': '48px',
              '--borderRadius': '50px',
              '--background': 'rgba(255, 255, 255, 0.1)',
              '--fontColor': '#fff',
              '--fontSize': '16px',
              '--fontWeight': 'bold',
              '--border': 'none',
              '--backgroundHover': 'rgba(255, 255, 255, 0.2)',
              '--fontColorHover': '#fff',
              ...props.cancelRenderStyle,
            }"
            @click="onCancelClick"
            >{{ props.cancelLabel ?? $$t("create.cancel") }}</Button>
          >
        </div>
        <div class="action-dialog-btn-margin" v-if="!props.hideCancel"></div>
        <div class="action-dialog-btn action-dialog-confirm flex1">
          <Button
            :renderStyle="{
              '--padding': '0',
              '--width': '100%',
              '--height': '48px',
              '--borderRadius': '50px',
              '--background': 'rgba(148, 173, 255, 1)',
              '--fontColor': '#000',
              '--fontSize': '16px',
              '--fontWeight': '500',
              '--border': 'none',
              '--backgroundHover': 'rgba(148, 173, 255, 0.8)',
              '--fontColorHover': '#000',
              ...props.confirmRenderStyle,
            }"
            @click="onConfirmClick"
            >{{ props.confirmLabel ??  $$t("create.confirm") }}</Button
          >
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { $$language, $$t } from "@/i18n/i18n.js";
import { reactive, ref, defineProps } from "vue";
import Dialog from "@/components/basic/dialog/Dialog.vue";
import Button from "@/components/basic/button/Button.vue";

const props = defineProps({
  tag: String,
  maskClose: {
    type: Boolean,
    default: false,
  },
  disableClose: {
    type: Boolean,
    default: false,
  },
  showCloseBtn: {
    type: Boolean,
  },
  hideCancel: {
    type: Boolean,
  },
  width: {
    type: String,
    default: "400px",
  },
  height: {
    type: String,
    default: "unset",
  },
  titleAlign: {
    type: String,
    default: "center",
  },
  title: String,
  subTitle: String,
  visible: {
    type: Boolean,
    default: false,
  },
  content: String,
  cancelLabel: String,
  confirmLabel: String,
  customClass: {
    type: String,
    default: "",
  },
  cancelRenderStyle: {
    type: Object,
    default: () => ({}),
  },
  confirmRenderStyle: {
    type: Object,
    default: () => ({}),
  },
});
const emits = defineEmits(["close", "confirm", "cancel"]);

const onCloseClick = () => {
  emits("close");
};

const onConfirmClick = () => {
  emits("confirm");
};

const onCancelClick = () => {
  emits("cancel");
};

onMounted(() => {});
</script>

<style lang="scss">
.action-dialog {
  > div.dialog-box {
    border-radius: 12px;
    padding: 40px 40px;
    
    backdrop-filter: blur(8px);
  }
  .dialog-header {
    font-family: HarmonyOS Sans SC;
    font-size: 22px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 12px;
  }
  .action-dialog-text {
    font-family: HarmonyOS Sans SC;
    font-size: 16px;
    font-weight: normal;
    line-height: 30px;
    color: #ffffff;
  }
  .action-dialog-text.cn {
    font-weight: normal;
  }
  .action-dialog-btns {
    margin: 28px 0 0 0;
    height: 48px;
    display: flex;
    justify-content: center;
  }

  .action-dialog-btn-margin {
    width: 12px;
  }
  .action-dialog-btn {
    height: 100%;
    width: 100%;
    flex: 1;
  }
  .action-dialog-btns.nocancel {
    .action-dialog-btn {
      max-width: 200px;
      margin: 0 auto;
    }
  }
}
</style>
