<template>
  <div class="login-regist-module height100-scroll">
    <div class="form-module">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" class="demo-ruleForm" :size="formSize" status-icon>
        <el-form-item label="" prop="email">
          <Input
            :value="ruleForm.email"
            :placeholder="$$t('login.input_your_email')"
            :prefix="LoginEmail"
            @change="
              (val) => {
                update('email', val)
              }
            "
          />
        </el-form-item>

        <el-form-item label="" prop="password">
          <Input
            :value="ruleForm.password"
            :placeholder="$$t('login.input_your_password')"
            :showPassword="true"
            :prefix="LoginLock"
            @change="
              (val) => {
                update('password', val)
              }
            "
            @enterPressed="onSubmit"
            :max="40"
          />
        </el-form-item>
      </el-form>
      <a class="forgot-password block"
        ><span class="pointer" @click="forgotmodal">{{ $$t('login.forgot_password') }}</span></a
      >
      <div class="login-regist-module-btn">
        <Button
          type="primary"
          :renderStyle="{
            '--width': '100%',
            '--height': '48px',
            '--borderRadius': '50px',
            '--background': 'linear-gradient(114deg, #4fdeff 21%, #e675ff 90%)',
            '--backgroundHover': 'linear-gradient(114deg, rgba(79,222,255,0.8) 21%, rgba(230,117,255,0.8) 90%)',
            '--fontColor': '#000',
            '--fontColorHover': '#000',
            '--fontSize': '16px',
            '--fontWeight': 'bold',
            '--border': 'none',
          }"
          @click="onSubmit"
          >{{ $$t('login.continue') }}</Button
        >
      </div>
      <ContinueWithThirdParty type="sign in" @continueWith="continueWith" />
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, computed, onMounted, readonly, reactive, watch, defineExpose, triggerRef } from "vue"
import Input from "@/components/basic/input/LoginInput2.vue"
import Button from "@/components/basic/button/Button.vue"
import ContinueWithThirdParty from "./ContinueWithThirdParty.vue"
import { useRouter } from "vue-router"
import LoginEmail from "@/assets/img/login_mail.png"
import LoginLock from "@/assets/img/login_lock.png"
import { $$language, $$t } from "@/i18n/i18n.js";

const Router = useRouter()
const props = defineProps({})
const emits = defineEmits(["submit", "continueWith", "forgotmodal"])
const ruleForm = reactive({
  // username: "", // 'test123',
  email: "", //'test@123.com',
  password: "", //'Qwe123456',
})
const ruleFormRef = ref(null)
const rules = {
  email: [
    {
      required: true,
      message: $$t('login.email_is_required'),
      trigger: "blur",
      pattern:
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
  ],
  password: [
    {
      validator: (rule, value, callback) => {
        if (value === "") {
          callback(new Error($$t('login.password_is_required')))
        } else if (value.length < 6) {
          callback(new Error($$t('login.enter_at_least')))
        } else if (value.length > 40) {
          callback(new Error($$t('login.Enter_no_more')))
        } else {
          callback()
        }
      },
      trigger: "blur",
    },
  ],
}
const update = (key, val) => {
  ruleForm[key] = val
}
const onSubmit = async (formEl) => {
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      emits("submit", ruleForm)
    }
  })
}

const forgotmodal = () => {
  emits("forgotmodal")
}

const continueWith = (...args) => {
  emits("continueWith", ...args)
}
onMounted(() => {})
</script>
<style lang="scss">
.login-regist-module {
  .login-regist-module-btn {
    width: 100%;
    // height: 48px;
  }
  .forgot-password {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 15px 0 15px 0;
    text-align: center;
    text-decoration: none;
    color: #fff;
    &:hover{
      opacity: 0.8;
    }
  }
}
</style>
